import Vue from "vue";
import Vuex from "vuex";
// 放置全局信息
Vue.use(Vuex);


export default new Vuex.Store({
  modules: {
    
  },
  state: {
    visitorId: '',
    fbopen: true,
    download: '',
    webp_feature: false,
    balance: 0,
    current_level: 0,
    hour3reddot: false,
    betlog: [],
    winner: [],
    bigaward: [],
    pcdomains: [
      ''
    ],
    is_pc: false,
    pixels: {
      //"123pp.cc": "360224773365306",
    }
  },
  mutations: {
    visitorId (state,data) {
      state.visitorId = data
    },
    fbopen (state,data) {
      state.fbopen = data
    },
    download (state,data) {
      state.download = data
    },
    is_pc (state,data) {
      state.is_pc = data
    },
    webp_feature (state,data) {
      state.webp_feature = data
    },
    balance (state,data) {
      state.balance = data
    },
    current_level (state,data) {
      state.current_level = data
    },
    hour3reddot (state,data) {
      state.hour3reddot = data
    },
    betlog (state,data) {
      if(data.length>1){
        state.betlog = []
        state.winner = []
        state.bigaward = []
      }
      for(var i = 0; i < data.length; i++) {
        state.betlog.unshift(data[i])
        if(data[i].result==1 && data[i].winloss>5000){
          state.winner.unshift(data[i])
        }
        if(data[i].result==1 && data[i].multiple>20){
          state.bigaward.unshift(data[i])
        }
      }
      if(state.betlog.length>10){
        state.betlog.pop()
      }
      if(state.winner.length>10){
        state.winner.pop()
      }
      if(state.bigaward.length>10){
        state.bigaward.pop()
      }
    },
  },
  actions: {},
  getters: {}
});