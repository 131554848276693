<template>
  <v-snackbar
    v-model="snackbar.show"
    :timeout="3000"
    top
    elevation="3"
    :color="snackbar.color"
  >
    {{ snackbar.text }}
    <template v-slot:action="{ attrs }">
      <v-btn
        v-bind="attrs"
        icon
        @click="snackbar.show = false"
      >
        X
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "Snackbar",
  components: {},
  props: {
    
  },
  data() {
    return {
      snackbar: {
        show: false,
        text: "",
        color: ""
      }
    };
  },
  created() {},
  methods: {
    info(mes) {
      this.snackbar = {
        show: true,
        color: "#2196F3",
        text: mes
      }
    },
    error(mes) {
     this.snackbar = {
        show: true,
        color: "#FF5722",
        text: mes
      }
    },
    warning(mes) {
      this.snackbar = {
        show: true,
        color: "#FF9800",
        text: mes
      }
    },
    success(mes) {
      this.snackbar = {
        show: true,
        color: "#4CAF50",
        text: mes
      }
    }
  }
};
</script>
