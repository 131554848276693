export default {

	ckcz: 'Depositar',
	sxsjj: '3h Click',
	tgzq: 'Indique e ganhe dinheiro',
 
	dating: 'Principal',
	rmyx: 'Populares',
	pgyx: 'PG Soft',
	slots: 'Slots',
	scj: 'Favoritados',
	yhhd: 'Ofertas',
	vipgb: 'VIP Club',
	help: 'Suporte',
	xitong: 'Tema',
	qianse: 'Clara',
	shense: 'Escuro',

	j30tck: 'Total de depósitos nos últimos 30 dias',
	jjzx: 'Bônus por depósito',
	grsz: 'Conta',
	tuichu: 'Sair',

	shouye: 'Principal',
	youhui: 'Ofertas',
	tuiguang: 'Ganhe',
	kefu: 'Suporte',

	denglu: 'Entrar',
	zhuce: 'Registrar',
	yqm: 'Código de referência',
	sryqm: 'Insira o código de referência',
	rgmyzwxtx: 'Deixe em branco caso não tenha um',
	shoujihao: 'Número do celular',
	qsrsjh: 'Insira seu número de celular',
	qsryhm: 'Insira seu nome de usuário',
	mima: 'Senha',
	qsrmm: 'Insira a senha',
	wjmm: 'Esqueceu a senha?',
	myzh: 'Não tem uma conta ainda?',
	yyzh: 'Você já tem uma conta?',
	czmm: 'Redefinir Senha',
	yzsjh: 'Número de Celular para Verificação',
	qsryzm: 'Código de verificação',
	hqyzm: 'Obter',
	next: 'Próxima',
	szxmm: 'Defina a nova senha',
	szmm: 'Insira a nova senha',
	qrmm: 'Confirme a senha',
	czcg: 'Senha redefinida com sucesso',
	mmszcg: 'Nova senha definida com sucesso',
	sjhmgscw: 'O celular não está em um formato válido.',
	yxgscw: 'O Email não está em um formato válido',
	zcsb: 'Erro!',
	hmycz: 'O número do celular foi registrado, faça o Login.',
	dlsb: 'Erro!',
	dlsbmmcw: 'Falha no login, número de celular ou senha inválidos',
  	dlsbbcz: 'O número do celular não existe',
	fssb: 'Erro!',
	yzsb: 'Erro!',
	czmmsb: 'Erro!',
	qsrxmm: 'Insira uma nova senha',
	qqrxmm: 'Confirme a nova senha',
	mmbyz: 'As senhas não correspondem',
	ktjebz: 'Saldo insuficiente para sacar',
	ckcgxe: 'Observe os valores mínimo e máximo de depósitos',
	tkcgxe: 'Observe os valores mínimo e máximo de saque',

	jcxx: 'Informações da Conta',
	touxiang: 'Avatar',
	yhm: 'Nome de usuário',
	sjh: 'Número do celular',
	wsz: 'Nenhum',
	sfxx: 'Informação Pessoais',

	zsxm: 'Nome completo',
	xing: 'Primeiro Nome',
	ming: 'Último Nome',
	shengri: 'Data de Nascimento',
	anquan: 'Segurança',
	xgmm: 'Alterar senha',
	xztx: 'Avatar',
	quxiao: 'Cancelar',
	qrtj: 'Salvar',
	szyhm: 'Nome de usuário',
	yzyx: 'Email para Verificação',
	qsryx: 'Insira seu Email',
	szzsxm: 'Nome completo',
	szxing: 'Último Nome',
	szming: 'Primeiro Nome',
	qsrzsxm: 'Insira seu Nome completo',
	qsrxing: 'Insira seu Último Nome',
	qsrming: 'Insira seu Primeiro Nome',
	szsr: 'Data de Nascimento',
	szcpf: 'Registre seu CPF',
	qsrcpf: 'Insira seu CPF',
	qsrjmm: 'Insira sua senha anterior',
	lcmmbyz: 'As senhas não correspondem',
	xgcg: 'Senha alterada',

	qianbao: 'Caixa',
	jiangjin: 'Bônus',
	jilu: 'Histórico',
	cunkuan: 'Depositar',
	tikuan: 'Sacar',
	myrhjl: 'Sem Dados',
	tian: 'Dias',
	jinri: 'Hoje',
	sxrq: 'Seleção de data',
	queding: 'Confirmar',
	daizhifu: 'Sem sucesso',
	wancheng: 'Sucesso',
	shibai: 'Falha',
	qxtk: 'Cancelamentos',
	yqbjzwb: 'Todas informações carregadas',
	jzgd: 'Mais',
	daitikuan: 'Pendente',
	jlxq: 'Detalhes',
	jine: 'Quantia',
	shijian: 'Data',
	zhuangtai: 'Situação',
	dingdan: 'Transação Nº.', 
	beizhu: 'Comentário',
	leixing: 'Tipo',
	quanding: 'Confirmar',

	type_bonus: 'Bônus por Despósito',
	type_hour3award: '3h Click Bônus',
	type_golden_pig: 'Cofrinho',
	type_weekly_deposit_bonus: 'Bônus por Dia',
	type_daily_rebate: 'Cashback de Apostas',
	type_invitation_bonus: 'Bônus por indicação',
	type_bet_commission: 'Comissão pelas Apostas',
	type_deposit_commission: 'Comissão pelos Depósitos',
	scjjmrcs: 'Bônus de Depósito',

	wdjj: 'Bônus ativado',
	lsjl: 'Histórico',
	ljlq: 'Total Pleiteado',
	daijiesuo: 'Desbloqueio Pendente',
	rwjd: 'Progresso',
	jxz: 'Em progresso',
	fangqi: 'Abandonar',
	lqjj: 'Coletar',
	hdlb: 'Lista de bônus',
	gzsm: 'Termos e Condições',
	lsyq: 'Requisito de Apostas',
	canjia: 'Participar',
	yswwcdjjrw: 'Um Bônus já está ativado!',
	tszncjyg: 'Por favor, primeiro complete o bônus ativo ou o abandone para ativar o próximo bônus.',
	zdl: 'Okay',
	qrfqgjjrw: 'Tem certeza de que deseja abandonar?',
	fqhgjjrwsx: 'Ao abandonar, não é possível recuperar o bônus e seu progresso feito será invalidado.',
	queren: 'Confirmar',
	gz1: 'Só é possível participar de um programa de bônus por vez',
	gz2: 'Complete o bônus ativo ou o abandone para ativar o próximo bônus',
	gz3: 'Para participar no programa de bônus você deve cumprir os requisitos de apostas e valor base',
	gz4: 'O bônus é adicionado ao seu saldo em dinheiro, que você pode usar para jogar na YeloBet.com ou sacar',
	cjjjhd: 'Participar do programa de bônus',
	hdmc: 'Tipo de bônus',
	kyye: 'Saldo total',
	qbye: 'Saldo da carteira',
	cyje: 'Valor base',
	qsrje: 'Insira o valor',
	jjbl: 'Percentual de bônus',
	jjje: 'Valor do bônus',
	wctzedhkhdjj: '*O bônus é recebido após cumprir o requisito de apostas',
	cglq: 'Coletado com sucesso',
	guanbi: 'Fechar',
	kyyebz: 'Saldo disponível insuficiente',
	qbyebz: 'Saldo insuficiente na carteira',
	cjjezsw: 'O valor mínimo é',
	qdyezh: 'Confirme para transferir o saldo do jogo de volta para a carteira',
	yjgj: 'Colete',

	zffs: 'Método de pagamento',
	ckje: 'Quantia',
	zengsong: 'Bônus',
	cjsczs100: 'Ganhe Bônus de 150% no seu primeiro depósito.',
	tkfs: 'Forma de saque',
	zjlx: 'Tipo de cartão',
	zjid: 'CPF',
	pixzhlx: 'Tipo de Chave PIX',
	zhanghao: 'Chave PIX',
	ktje: 'Saldo em caixa',
	mctkxe: 'Limite de saque',
	tksqytj: 'Sua retirada está sendo processada',
	fanhui: 'Fechar',  
	jjsm: 'Termos e Condições',
	jjsmnr: 'xxxxxxxxxxxxx',
	zmfk: 'Como posso pagar',
	zmfk2: 'Abra o aplicativo do seu banco ou de pagamento, navegue até a Área PIX, selecione “Copiar e Colar PIX” ou “Ler código QR” e conclua o pagamento!',
	fzpixcode: 'Copie o código PIX',
	fzcg: 'Copie com sucesso',
	ckmx: 'Detalhe dos depósitos',
	qz10fznfk: 'Por favor, conclua o pagamento dentro de 10 minutos',
	smewm: 'Digitalize o código QR',
	yxyezdgj: 'Coleta de dinheiro do jogo',
	tjcg: 'Concluído com sucesso',
	wyzfcg: 'Pagamento Completado',

	tjbzq: 'Indique e Ganhe Dinheiro',
	yqpybhd: 'Indique um Amigo e Ganhe',
	yibiaopan: 'Painel',
	tdgl: 'Minha Equipe',
	tzyj: 'Comissão pelas Apostas',
	ckyj: 'Comissão pelos Depósitos',
	tjjj: 'Bônus por Indicação',
	wdljyj: 'Visão geral das comissões',
	yqjj: 'Bônus por Indicação',
	yqpyzq: 'Compartilhe links e ganhe dinheiro!',
	yqlj: 'Seu link para indicação',
	wdyjbl: 'Minha taxa de comissão',
	wddj: 'Meu nível de indicação',
	yongjin: 'Comissão',
	zkqb: 'Ver mais',
	zhedie: 'dobrar',
	sjtj: 'Progresso de nível',
	yxyq: 'Depositantes',
	ljyj: 'Comissão acumulada',
	yqjl: 'Bônus por indicação',
	zcbljckdd: 'Registre-se e faça depósitos cumulativos de',
	tgndyqlj: 'Para cada jogador que se registrar através do seu link e fizer um depósito, você receberá um bônus por indicação. Quanto mais pessoas você convidar, mais bônus você ganha!',
	yaoqing: 'Indique ',
	ygyxyh: '1 jogador ativo',
	zuigao: 'Máximo ',
	yonghu: 'Jogador',
	chaoguo: 'mais que',
	touzhu: 'apostador',
	yhckcg: 'Jogador deposita mais que R$ 200	',
	yhtzcg: 'Jogador aposta mais que R$ 2.000',
	mryqrs: 'Indicações diárias',
	khdjjmr: 'Bônus/por convidado',
	tghyjb: 'Níveis de indicação',
	ljyjjkl: '* O acúmulo de comissão considera apenas a comissão de depósito e a comissão de apostas',
	dengji: 'Nível',
	yjbl: 'Taxa de comissão',
	guanxi: 'Graus',
	yqjjpm: 'Ranking de indicações e seus bônus',
	yjjjpm: 'Ranking de comissão e totais recebidos',
	ktyj: 'Comissões Recebíveis',
	lingqu: 'Coletar',
	ytyj: 'Comissões Coletadas',
	zyqs: 'Multinível',
	ren: 'Jogadores',
	yjrs: 'Total de graus 1',
	ejrs: 'Total de graus 2',
	sjrs: 'Total de graus 3',
	quanbu: 'Total',
	zuori: 'Ontem',
	benzhou: 'Esta semana',
	benyue: 'Este mês',
	shangyue: 'Mês passado',
	zyqjj: 'Total do bônus por indicação',
	ztzyj: 'Comissão total por apostas',
	zckyj: 'Comissão total por depósitos',
	ztjrs: 'Multinível',
	yjyj: 'Grau 1',
	ejyj: 'Grau 2',
	sjyj: 'Grau 3',
	qbdj: 'Todos os Graus',
	zcrq: 'Data de registro',
	sousuo: 'procurar',
	zcke: 'Total de depósitos',
	ztze: 'Total de apostas',
	yjrq: 'Data',
	tzrs: 'Número de apostadores',
	lqsj: 'Tempo de coleta',
	ckrs: 'Número de depositantes',
	yxyqjj: 'Bônus por indicação',
	jjrq: 'Data',
	zcyhs: 'Número de registrados',

	qbvipdj: 'Todos os níveis de VIP',
	dqdj: 'Nível atual',
	j30tljck: 'Total de depósitos nos últimos 30 dias',
	rgysjd: 'Para subir ao nível de',
	nxyzck: 'Ainda é necessário depositar',
	daojishi: 'Contagem',
	cxg: 'Cofrinho',
	zckjj: 'Bônus por Dia',
	zckjj_sub: 'Bônus semanal de Até 150%',
	rfsjj: 'Cashback Diário',
	lhjfs: 'Cashback dos Slots',
	zrfs: 'Cashback do Cassino ao vivo',
	yidacheng: 'Alcançado',
	dengjiyaoqiu: 'Requisito',
	sstckxdd: 'Os depósitos dos últimos 30 dias precisam ser superior a',
	hxyzck: 'Ainda é necessário depositar',
	quanyi: 'Benefícios VIP',
	laohuji: 'Slots',
	zhenren: 'Cassino Ao Vivo',

	hdsj: 'Período da promoção',
	paixu: 'Ordenar por',
	wdgd: 'Meus Tickets',
	tjxgd: 'Enviar um novo ticket',
	cjwt: 'FAQ',
	gengduo: 'mais',
	youbz: 'útil',
	meiyong: 'inútil',
	sfzxkf: 'Desejo contatar o suporte',

	qbtz: 'Jogadores',
	dayingjia: 'Ganho grande',
	gbdj: 'Apostas de sorte',
	youxi: 'Jogo',
	wanjia: 'Jogador',
	beishu: 'Multiplicador',
	shuying: 'Pagamento',

	suoding: 'Bloqueado',
	wxts: 'Dicas',
	ddcnlq: 'Disponível para níveis de VIP 2 ou acima',
	mzznlqyc: 'Somente pode pleiteado uma vez por semana',

	cjwd: 'FAQ',
	cpfgeshi: 'CPF deve ter 11 dígitos',
	kyyetip: 'Antes do saque, o valor do depósito precisa ser apostado totalmente',
	mfzs: 'Bônus Grátis de Registro de',
	mfzs2: 'Registre seu CPF e consiga bônus de R$ 7,77 grátis!',
	cpftip1: 'Receba R$ 2 em dinheiro de volta imediatamente após seu primeiro depósito',
	cpftip2: 'Não é possível modificar após o registro',

	riqi: 'Data',
    ewjl: 'Recompensa Extra',
    zhouyi: 'Segunda-feira',
    zhouer: 'Terça-feira',
    zhousan: 'Quarta-feira',
    zhousi: 'Quinta-feira',
    zhouwu: 'Sexta-feira',
    zhouliu: 'Sábado',
    zhouri: 'Domingo',
    yilingqu: 'Coletada',
    weilingqu: 'Perdida',
    zckjj1: 'Para cada R$ 500 que você depositar durante uma semana, você receberá bônus diários e recompensas extras na próxima semana!',
    zckjj2: 'Faça login e resgate sua recompensa diária todos os dias. Um bônus diário não estará mais disponível no dia seguinte se você perder!',
    zckjj3: 'Receba R$ 18 extras aos domingos se resgatar seu bônus mais de 5 da semana e o dobro de bônus às quintas-feiras!',
    zckjj4: 'Seu bônus também aumenta com o seu nível no Clube VIP!',
	szlj: 'Depósito acumulado na última semana',
    weiman: 'Menos de',
    yiman: 'Mais que',

    ywccpfsz: 'Registro de CPF preenchido',
    xiazai: 'Baixar',
		xiazai_tip_text: 'Experiência de jogo melhor',
    srwz: 'Digite o site oficial correto',
    qzxfsr: 'Por favor, digite abaixo',
    websiteerror: 'Entrada incorreta, digite: YeloBet.com',
		xcnr: 'Poste a captura de tela da página da web no Facebook e no Grupo Público',

	newtip_biaoti: 'Melhor experiência',
	newtip_neirong: 'Instale o App YeloBet.com ™ para a melhor experiência de jogo',
	
	reg_freebonus: 'Ganhe R$ 12,77 Cadastre-se agora!',

	azdsjzm: 'Instale o aplicativo YeloBet.com™',
	azdsjzmnr1: 'Clique',
	azdsjzmnr2: 'neste ícone para instalar o YeloBet.com APP em seu telefone no Safari',
	rhcz: 'Como',
	wyaz: 'Eu instalei',

	wckts: 'Os jogadores que não depositam podem sacar até R$ 30 usando o bônus de inscrição grátis, o saldo restante é deduzido.',
	wckts2: 'Publique a captura de tela da página no Facebook e Grupo Público, sua retirada será processada mais rapidamente!',

	scjtbt: 'Publique seu link de convite em um grupo público do Facebook',
	scjt: 'Carregar capturas de tela',
  lq_x: 'Receba',
  x_jj: 'de bônus',
	jlck: 'Exemplo',
	daishenhe: 'Sob revisão',
  yifafang: 'Pago',
  weitongguo: 'Rejeitar',
	tiezilianjie: 'Postar link',
	tijiao: 'submeter',
	upimg_a01: 'Por favor, use seu link de indicação e faça uma postagem promovendo nosso site.',
	upimg_a02: '(Exemplo: Mostrando o bônus de inscrição de R$ 7,77 para novos jogadores)',
	upimg_a03: 'Publique em qualquer Grupo público do Facebook.',
	guize: 'Regras',
	upimg_b01: 'As capturas de tela enviadas precisam ser revisadas por nós.',
	upimg_b02: 'A revisão será concluída em 1 hora.',
	upimg_b03: 'Se você passar nessa avaliação, daremos uma recompensa de R$ 2.5 por publicación em sua conta, 5 vezes ao dia.',
	upimg_b04: 'Certifique-se de que cada captura de tela enviada seja uma nova publicações no Facebook',
	zs1zt: 'Carregue pelo menos uma imagem',
	tgljbnwk: 'Por favor, preencha o link da postagem',
	tgljycz: 'O link para a postagem já existe',
	xctjsj: 'Fazer uma publicação no Facebook 1 hora depois e carregue uma nova captura de tela.',
	xctjsj2: 'Você pode enviar mensagens do WhatsApp novamente após 1 hora',
	ytzntjyc: 'Você carregou hoje. Apenas 5 vezes por dia.',
	ytzntj2c: 'Você carregou 5 vezes hoje, apenas 5 vezes por dia.',
	ytzntj2c2: 'Você carregou 2 vezes hoje, apenas 2 vezes por dia.',
	yjfz: 'Cópia rápida',
	ljgscw: 'Digite o link correto da publicação',
	xzwfsy: 'Não disponível agora, poste e carregue entre 09h00 e 24h00 disponível',

	post_ad1: 'Publicar num Grupo do FB',
	shezhi: 'Configurar',
	shangchuan: 'Carregar',
	wcCPFsz: 'Registrar seu CPF',
  upappimg: 'Compartilhe via WhatsApp para ganhar bônus',
  upappimg2: 'Carregar capturas de tela',
  shsb_cxsc: 'Falha na revisão, faça o upload novamente.',
  upappimg_rule1: 'Clique no Whatsapp👇 abaixo para enviar o link do seu convite para pelo menos 20 amigos.',  
  upappimg_rule2: 'Carregue uma captura de tela do Whatsapp após o envio bem-sucedido.',  
  upappimg_rule3: 'Após a publicação, envie uma captura de tela da sua postagem no Facebook para reivindicar o bônus de R$ 5,77',

	post_tg: 'Compartilhar no Whatsapp',
	post_tg_img: 'Compartilhe via Whatsapp para ganhar R$ 6',
	post_tg_end: 'Apenas 1 upload por dia, você pode continuar compartilhando amanhã',
  post_tg_rule1: 'Clique no Whatsapp👇 abaixo para enviar o link do seu convite para pelo menos 20 amigos.',
  post_tg_rule2: 'Carregue uma captura de tela do Whatsapp após o envio bem-sucedido.',
	post_tg_rule2a: 'Analisaremos a captura de tela que você enviou em até 1 hora',
	post_tg_rule2b: 'Iremos recompensá-lo com R$ 3, 2 vez ao dia.',
	post_tg_rule2c: 'Certifique-se de que a imagem que você carrega a cada dia seja um novo compartilhamento. ',

	post_fb_tt: 'Compartilhe um Post + Grande Sorteio de R$ 10.000',
  post_fb_tt2: 'Compartilhe uma publicação  para ter a chance de ganhar dinheiro + Samsung e iPhone 15',
  huodong: 'Evento',
  kaijiangjieguo: 'Vencedores',
  canyurenshu: 'Total de participantes',
  huodongshijian: 'Anunciaremos a lista de vencedores em 20.dez.2023',
  post_fb_bonus1: '1º prêmio: R$ 2.000',
  post_fb_bonus2: '2º prêmio: R$ 500',
  post_fb_bonus3: '3º prêmio: R$ 1000',
  post_fb_bonus4: '4º ao 10º prêmio: R$ 100',
  post_fb_bonus5: '11º ao 100º prêmio: R$ 50',
  post_fb_bonus6: 'Prêmio 101-200: R$ 20',
  post_fb_rule1: 'Clique em 👇 para compartilhar a publicação e responder aos comentários + @seus amigos',
  post_fb_rule2: 'Envie sua captura de tela compartilhada',
  post_fb_rule3: 'Analisaremos sua captura de tela enviada.',
  post_fb_rule4: 'Aguardando a lista do sorteio ser anunciada em 20/12/2023',
	post_fb_btn: 'Facebook Lucky draw',
	daikaijiang: 'Esperando o sorteio',
	zhongjiang: 'Ganhando',
	weizhongjiang: 'Não ganhei',
	post_ad3: 'Ganhe Iphone & Samsung & Dinheiro',

	dlcgts: 'Você fez login com sucesso.',
  ckts: 'Deposite e divirta-se jogando!',
  sc100jj: 'Deposite agora e ganhe ovos de ouro amanhã',
	ljck: 'Deposite agora',

	mtsdyj: "Você pode receber a comissão de hoje amanhã",
	ni: 'Você',
	yjms: 'Modelo de comissão',
	cengji: 'Nível',
	fsxxzqjj: 'Envie mensagem no SMS e ganhe recompensas',
	bwmfs: 'Clique no número abaixo👇 para nos ajudar a enviar mensagens de marketing',
	mwc10t: 'Envie 10 mensagens, você pode ganhar',
	yjfs: 'Already sent',
	ytzntj2c3: '2 tarefas de marketing do SMS em um dia',

	sendws: 'Ganhe dinheiro enviando SMS',

	browser_tip1: 'Método um',
	browser_tip2: 'Método dois',
	browser_tip_dianji: 'Clicado',
	browser_tip_xuanze: 'Escolher',
	browser_tip1_1: 'Abra a barra de menus',
	browser_tip1_2: 'Aberto no navegador',
	browser_tip2_1: 'Copiar',
	browser_tip2_2: 'Cole no navegador para abrir',

	mrzjdbtn: 'Ovos de Ouro da Sorte',
	mrzjdbtn_i: 'Ovo de Ouro da Sorte Diário',
	mrzjd: 'Invocação dos Ovos Dourados da Sorte',
	mrzjd2: 'Compartilhe suas recompensas com seus amigos via WhatsApp',
	hyjhcs1: 'Você tem',
	hyjhcs2: 'chances hoje',
	mrzjdgz1: 'Deposite hoje ≥ R$20, Ganhe 1 ovo de ouro amanhã',
	mrzjdgz2: 'Deposite hoje ≥ R$30, Ganhe 2 Ovos de Ouro amanhã',
	mrzjdgz3: 'Deposite hoje ≥ R$50, Ganhe 3 Ovos de Ouro amanhã',
	zrck: "Depósito de ontem",

	fbspyqjj: "Envie um vídeo no Kwai, Youtube",
	mtlqjl: "Ganhe R$ 6 de recompensa todos os dias",
	xzrhfb: "Baixe e salve o vídeo em seu álbum e publique o vídeo em seu Kwai ou Youtube (Não carregue vídeos SLOTS para o tiktok, eles serão excluídos imediatamente pelo tiktok)",
	fsytpl: "Após publicar, copie o texto abaixo e deixe um comentário, e edite seu perfil -> Bio",
	scjtlink: "Envie sua captura de tela de vídeo e LINK de compartilhamento de vídeo. Caso seu vídeo seja aprovado, você será recompensado com R$ 1, 6 vezes por dia",
	bzfbrq: "Certifique-se de que o horário de publicação seja hoje.",
	mydymjl: "Com base nas visualizações de cada vídeo, selecionaremos o primeiro colocado todos os meses e receberemos uma recompensa de R$ 2000",
	bcspxz: "Baixar video",
	upvideo: 'Ganhe R$ 6 - Envie e publique vídeo!',

	giveaway: 'Faça seu primeiro depósito e pagaremos R$ 2 no seu PIX, Confira Agora',

	license_nav: 'Licença de jogo',
	license_tt: 'Licença De Curaçao',
  license_subtt: 'YeloBet.com – Cassino Responsável',
  license_nei: 'A Licença de Jogos de Curaçao é uma das licenças de jogos eletrônicos mais populares do mundo. Os principais fornecedores de software são licenciados pelo Conselho de Controle de Jogos de Curaçao. Uma licença abrange todos os jogos, como cassinos online, caça-níqueis, apostas esportivas e jogos eletrônicos. Atletismo, loterias e jogos de habilidade e azar. Esta agência de licenciamento é apoiada pelo governo de Curaçao e foi criada para garantir que todas as operadoras cumpram a estrutura regulatória e o código de conduta. A seguir está a descrição da licença da plataforma (YeloBet.com). Por favor, não roube e infratores será processado.',

	freespins_t1: 'Jogue rodadas grátis',
  freespins_t2: 'agora',
  freespins_t3a: 'Você tem',
  freespins_t3b: 'rodadas grátis no total',
	freespins_t4: 'Rodadas Grátis disponíveis agora',
  freespins_t5: 'As Rodadas Grátis restantes podem ser usadas amanhã',
  freespins_tip1: 'Como funcionam os bônus de rodadas grátis?',
  freespins_tip2: 'Rodadas grátis em um jogo de slot que você pode desfrutar sem apostar seu dinheiro real no resultado. As Rodadas Grátis são concedidas aos jogadores em determinadas situações, como uma recompensa por um Desafio concluído ou como parte de uma promoção.',
  freespins_tip3: '*Receba números variados de rodadas grátis diariamente com base no valor de cada um dos seus 5 primeiros depósitos. Você pode usar metade das rodadas grátis de hoje imediatamente e guardar a metade restante para usar amanhã.',
  freespins_tip4: 'Cada depósito ≥ R$ 10, ganhe 1 rodada grátis',
  freespins_tip5: 'Cada depósito ≥ R$ 50, ganhe 5 rodadas grátis',
  freespins_tip6: 'Cada depósito ≥ R$ 100, ganhe 12 rodadas grátis',
  freespins_tip7: 'As rodadas grátis podem ser usadas em jogos elegíveis específicos, com os ganhos convertidos em dinheiro real do Casino Instant assim que cada rodada grátis for concluída.',
  freespins_tip8: 'Agora rodadas grátis disponíveis apenas no PG -FORTUNE TIGER, FORTUNE RABBIT, FORTUNE MOUSE, FORTUNE OX',
	freespins_tip9: 'As rodadas grátis são jogadas com a mesma aposta total de R$0,4',
	freespins_btn: 'Entre para jogar rodadas grátis',

  freespins_dtip1: 'Faça o depósito e ganhe',
  freespins_dtip2: 'rodadas grátis',
  nofreespins_tip: 'Você não tem rodadas grátis',

  safaritips: 'Use o safari para visitar este Link (cole na barra de URL) e siga as dicas abaixo para instalar o APP para uma melhor experiência',
}