import CryptoJS from 'crypto-js'

const KEY = '1234567890123456';
const IV = '1234567890123456';

export function Encrypt(data) {//加密
  var key_hash = CryptoJS.MD5(KEY).toString();//256加密使用32位key
  var key = CryptoJS.enc.Utf8.parse(key_hash);  
  var iv = CryptoJS.enc.Utf8.parse(IV);
  var encrypted = CryptoJS.AES.encrypt(data, key,
      {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
  return encrypted.toString();//返回的是base64格式的密文
}
export function Decrypt(data) {//解密
  var key_hash = CryptoJS.MD5(KEY).toString();
  var key = CryptoJS.enc.Utf8.parse(key_hash);
  var iv = CryptoJS.enc.Utf8.parse(IV);
  var decrypted = CryptoJS.AES.decrypt(data, key,
      {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
      });
  return decrypted.toString(CryptoJS.enc.Utf8);
}