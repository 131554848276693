<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import store from "./store/"
import FingerprintJS from '@fingerprintjs/fingerprintjs'
export default {
  name: 'App',
  data: () => ({

  }),
  watch: {  
 
  },
  mounted() {
    document.addEventListener('visibilitychange', this.visibilitychange)
    this.check_webp_feature('lossy')

    if(this.$store.state.pcdomains.indexOf(window.location.host)==-1){
      console.log('is_pc', false)
      store.commit('is_pc', false)
    }else{
      console.log('is_pc', true)
      store.commit('is_pc', true)
    }
    this.getvisitorId()
  },
  methods: {
    visibilitychange() {
      if (document.visibilityState === 'hidden') {
        console.log('hidden')
      }
      if (document.visibilityState === 'visible') {
        console.log('visible')
      }
    },
    check_webp_feature(feature) {
      let kTestImages = {
        lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
        lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
        alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
        animation: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
      }
      let img = new Image()
      img.onload = () => {
        let result = (img.width > 0) && (img.height > 0)
        console.log(feature, result)
        store.commit('webp_feature', result)
      };
      img.onerror = () => {
        console.log(feature, false)
      }
      img.src = "data:image/webp;base64," + kTestImages[feature]
    },
    async getvisitorId() {
      const fp = await FingerprintJS.load()
      const result = await fp.get()
      const visitorId = result.visitorId
      //console.log('visitorId', visitorId)
      store.commit('visitorId', visitorId)
    },
  }
};
</script>
