import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store"
import vuetify from './plugins/vuetify'
import VueClipboard from 'vue-clipboard2'
import i18n from './plugins/i18n'
import {server} from './service'
import snackbar from './components/snackbar/'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueFacebookPixel from 'vue-facebook-pixel'
import dayjs from 'dayjs'
import VueFullscreen from 'vue-fullscreen'

Vue.use(VueFullscreen)

Vue.use(VueAwesomeSwiper)
Vue.use(VueFacebookPixel, {"debug": true})

Vue.use(VueClipboard)

Vue.config.productionTip = false

//定义全局变量
Vue.prototype.$server=server
Vue.prototype.$snackbar = snackbar

Vue.prototype.$dayjs = dayjs

import {getStorage, setStorage, removeStorage} from "./module/storage"
Vue.prototype.getStorage=getStorage
Vue.prototype.setStorage=setStorage
Vue.prototype.removeStorage=removeStorage

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
