<template>
  <v-dialog
    v-model="showdialog"
    width="550"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card color="app_bg">
      <v-card-title class="nav_bg">
        <template v-if="bottomvalue=='wallet'">
          <v-icon left>
            mdi-wallet
          </v-icon>
          {{ $t('qianbao') }}
        </template>
        <template v-if="bottomvalue=='record'">
          <v-icon left>
            mdi-history
          </v-icon>
          {{ $t('jilu') }}
        </template>

        <v-spacer />

        <v-btn
          icon
          @click="closeprofile"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>            
      </v-card-title>

      <template v-if="bottomvalue=='wallet'">
        <Wallet @closeprofile="closeprofile" />
      </template>

      <template v-if="bottomvalue=='record'">
        <Record :tabrecord="tabrecord" />
      </template>
    </v-card>

    <v-bottom-navigation
      app
      height="65"
      :value="bottomvalue"
      color="primary"
      class="d-flex d-sm-none"
    >
      <v-btn
        value="wallet"
        @click="changetype('wallet')"
      >
        <span>{{ $t('qianbao') }}</span>
        <v-icon>mdi-wallet</v-icon>
      </v-btn>

      <v-btn
        value="record"
        @click="changetype('record')"
      >
        <span>{{ $t('jilu') }}</span>
        <v-icon>mdi-history</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-dialog>
</template>
<script>
import Wallet from '@/components/Wallet.vue'
import Record from '@/components/Record.vue'
export default {
  name: 'Profile',
  components:{
    Wallet,
    Record
  },
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data: () => ({
    showdialog: true,
    bottomvalue: '',
    tabrecord:'tabrecord-1'
  }),
  computed: {

  },
  watch:{
    showdialog: function(nv,ov){
      console.log(nv,ov)
      this.closeprofile()
    },
  },
  created() {
    this.bottomvalue=this.value
  },
  mounted() {

  },
  methods: {
    closeprofile() {
      this.showdialog = false
      this.$emit('closeprofile')
    },
    changetype(type) {
      if(this.bottomvalue==type){
        return false
      }
      this.bottomvalue=type
    },
    promohistory() {
      this.bottomvalue = 'record'
      this.tabrecord = 'tabrecord-3'
    }
  },
};
</script>