<template>
  <div class="app_bg">
    <v-tabs
      v-if="!showQrcode && !showWaiting"
      v-model="tab_wallet"
      centered
      background-color="nav_bg"
    >
      <v-tab :href="`#deposit`">
        {{ $t('cunkuan') }}
      </v-tab>
      <v-tab :href="`#withdraw`">
        {{ $t('tikuan') }}
      </v-tab>
      <v-tabs-items
        v-model="tab_wallet"
      >
        <v-tab-item
          class="app_bg pb-16 pb-sm-0"
          value="deposit"
        >
          <Deposit
            @qrcodeshow="qrcodeshow(arguments)"
            @fanhui="fanhui"
          />
        </v-tab-item>

        <v-tab-item
          class="app_bg pb-16 pb-sm-0"
          value="withdraw"
        >
          <v-card-text>
            <form>
              <div class="input-block">
                <div class="d-flex align-center justify-space-between text--disabled mb-2">
                  <span>{{ $t('kyye') }}</span>
                  <span class="d-flex align-center justify-center">
                    <v-img
                      width="16"
                      src="../assets/img/icons/USD.png"
                    />
                    <strong class="orange--text mx-1">{{ balance }}</strong>
                    <span>{{ meminfo.currency }}</span>
                    <v-btn
                      icon
                      @click="refresh"
                    >
                      <v-icon color="grey">mdi-refresh</v-icon>
                    </v-btn>
                  </span>
                </div>
              </div>

              <div
                class="input-block"
              >
                <div class="mt-3 mb-1 text--disabled">
                  {{ $t('jine') }}
                </div>
                <v-text-field
                  v-model.number="withdraw_money"
                  type="number"
                  filled
                  solo
                  flat
                  background-color="btn_bg"
                  :error-messages="withdraw_money_error"
                  :suffix="meminfo.currency"
                  :placeholder="$t('qsrje')"
                  :hint="$t('mctkxe')+': '+min_amount+' ~ '+max_amount+' '+meminfo.currency"
                  persistent-hint
                  class="grey--tex text-body-2"
                />

                <div class="mt-3 mb-1 text--disabled">
                  {{ $t('beizhu') }}
                </div>
                <v-textarea
                  v-model="remark"
                  solo
                  flat
                  background-color="btn_bg"
                  name="input-7-4"
                  placeholder="Enter the remark content (such as withdrawal account information, etc.)"
                  hide-details="auto"
                />
              </div>

              <v-btn
                depressed
                block
                large
                color="primary"
                class="text-none mt-5"
                @click="cashout_apply"
              >
                {{ $t('queren') }}
              </v-btn>
            </form>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>

    <div
      v-if="showQrcode"
      ref="wallet"
      class="pb-16 pb-sm-0"
    >
      <v-card
        flat
        color="app_bg"
      >
        <v-card-subtitle class="pb-0">
          <v-img
            width="150"
            src="../assets/img/logos/pay-pix.png"
            class="mx-auto"
          />
        </v-card-subtitle>
        <v-card-text>
          <div class="text-center pb-3">
            <span class="mr-2">{{ $t('ckje') }}:</span>
            <strong>R$</strong>
            <strong
              class="title green--text"
            >
              {{ select_amount }}
            </strong>
            USD

            <v-btn
              icon
              small
              right
              color="grey darken-1"
              @click="doCopy(select_amount.toString())"
            >
              <v-icon small>
                mdi-content-copy
              </v-icon>
            </v-btn>
          </div>

          <v-sheet
            rounded
            class="box_bg px-3 pb-5"
          >
            <v-subheader>{{ $t('ckmx') }}</v-subheader>

            <v-alert
              text
              dense
              color="primary"
              icon="mdi-clock-fast"
              border="left"
              class="caption"
            >
              {{ $t('qz10fznfk') }}
            </v-alert>

            <div class="text-center py-3">
              <v-img
                width="150"
                height="150"
                :src="erweima"
                class="mx-auto"
              />
              <small>{{ $t('smewm') }}</small>
            </div>

            <v-divider class="opacity-3" />
            
            <v-text-field
              outlined
              readonly
              hide-details
              label="PIX CODE"
              class="mb-3"
              :value="qr_code_omit"
              @click="doCopy(qr_code)"
            />

            <v-btn
              block
              depressed
              color="primary"
              class="text-none"
              @click="doCopy(qr_code)"
            >
              <v-icon left>
                mdi-content-copy
              </v-icon>
              {{ $t('fzpixcode') }}
            </v-btn>
          </v-sheet>

          <div class="my-3 text-disabled">
            <v-subheader class="subtitle-2">
              {{ $t('zmfk') }}?
            </v-subheader>
            <p class="px-4">
              {{ $t('zmfk2') }}
            </p>
          </div>
        </v-card-text>

        <div class="text-center pb-5">
          <v-btn
            depressed
            rounded
            dark
            color="grey darken-4"
            @click="depositcompleted"
          >
            <v-icon left>
              mdi-cash-check
            </v-icon>
            {{ $t('wyzfcg') }}
          </v-btn>
        </div>
      </v-card>
    </div>

    <div
      v-if="showWaiting"
      ref="wallet"
      class="pb-16 pb-sm-0"
    >
      <v-card
        flat
        color="app_bg pa-5 pt-md-0"
      >
        <v-card-text
          class="text-center"
        >
          <div class="my-5">
            <v-icon
              x-large
              color="primary"
              style="font-size: 64px!important"
            >
              mdi-check-circle
            </v-icon>
          </div> 
          <h3>
            {{ $t('tksqytj') }}!
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            depressed
            color="btn_bg"
            class="pa-3"
            @click="fanhui"
          >
            {{ $t('fanhui') }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </div>
    <v-overlay :value="showprogress">
      <v-progress-circular
        indeterminate
      />
    </v-overlay>

    <v-dialog
      v-model="tipDialog"
      max-width="300"
      scrollable
    >
      <v-card class="box_bg">
        <v-card-title class="text-subtitle-1">
          {{ $t('ktje') }}
        </v-card-title>
        <v-card-text>
          {{ $t('kyyetip') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="tipDialog = false"
          >
            {{ $t('zdl') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="tipFree"
      max-width="300"
      scrollable
    >
      <v-card class="box_bg">
        <v-card-title class="text-subtitle-1">
          <v-icon
            small
            left
          >
            mdi-bell
          </v-icon>
          {{ $t('wxts') }}
        </v-card-title>
        <v-card-text>
          {{ $t('wckts') }}
          <p class="font-weight-medium amber--text">
            {{ $t('wckts2') }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="tipFree = false"
          >
            {{ $t('quxiao') }}
          </v-btn>

          <v-spacer />
          <v-btn
            color="green darken-1"
            dark
            depressed
            @click="agree"
          >
            {{ $t('zdl') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {store2} from '../store2.js'
import store from "../store/"
import Deposit from '@/components/Deposit.vue'
  export default {
    name: 'Wallet',
    components: {
      Deposit
    },
    data: () => ({
      showQrcode: false,
      showWaiting: false,
      tipDialog: false,
      tipFree: false,
      meminfo:{
        assets: {
          withdrawable:0,
        }
      },
      balance: 0,
      tab_wallet: 'deposit',
      withdraw_money:'',
      withdraw_money_error: '',
      remark: '',
      min_amount: 10,
      max_amount: 50000,
      pix_types: ['CPF'],
      pix_type: 'CPF',
      card_types: ['CPF'],
      card_type: 'CPF',
      card_no: '',
      realname: '',
      select_amount: 0,
      qr_code:'',
      qr_code_omit: '',
      erweima:'',
      realnamedisabled: true,
      card_nodisabled: true,
      pix_nodisabled: true,
      cpferror: false,
      realnameerror: false,
      pix_noerror: false,
      showprogress: false
    }),
    computed: {
      pix_no:{
        get: function () {
          if(this.pix_type=='CPF'){
            return this.card_no
          }else{
            return ''
          }
        },
        set: function (newVal) {
          
        }
      }
    },
    watch:{
      tab_wallet: {
        handler(newVal, oldVal) {
          console.log(newVal, oldVal)
          if(newVal=='withdraw'){
            this.collect()
          }
        }
      },
      pix_type: {
        handler(newVal, oldVal) {
          if(newVal=='CPF'){
            if(this.card_nodisabled){
              this.pix_nodisabled = true
            }else{
              this.pix_nodisabled = false
            }
          }else{
            this.pix_nodisabled = false
          }
        }
      },
      '$store.state.balance': function (newVal) {
        this.balance = newVal
      },
      showprogress (val) {
        val && setTimeout(() => {
          this.showprogress = false
        }, 5000)
      },
    },
    created() {
      this.tab_wallet = store2.wallet_currtab
      
    },
    mounted() {
      this.getmeminfo()
      setTimeout(() => {
        if(this.meminfo.realname){
          this.realname = this.meminfo.realname
          this.realnamedisabled = true
        }else{
          this.realname = localStorage.getItem('realname')??''
          this.realnamedisabled = false
        }
        if(this.meminfo.cpf){
          this.card_no = this.meminfo.cpf
          this.card_nodisabled = true
        }else{
          this.card_no = localStorage.getItem('card_no')??''
          this.card_nodisabled = false
        }

        this.pix_type = localStorage.getItem('pix_type')??'CPF'
        this.pix_no = localStorage.getItem('pix_no')??''
      }, 3000)
    },
    methods: {
      refresh() {
        this.getmeminfo()
      },
      getmeminfo() {
        let paramObj = {
          player_id: this.getStorage('player_id'),
        }
        this.$server.getmeminfo(paramObj).then((response) => {
          if(response.code==200){
            this.meminfo = response.data
            this.balance = response.data.total_balance
            store.commit('balance', this.balance)
          }
        })
      },
      collect() {
        this.$snackbar.info(this.$t('yxyezdgj'))
        let paramObj = {
          player_id: this.getStorage('player_id'),
        }
        this.$server.collect(paramObj).then((response) => {
          if(response.code==200){
            this.getmeminfo()
          }
        })
      },
      withdraw() {
        if (this.realname=='') {
          this.realnameerror = true
          return false
        }else{
          this.realnameerror = false
        }

        var cpf_regExp = /^\d{11,11}$/
        if (!cpf_regExp.test(this.card_no)) {
          this.cpferror = true
          return false
        }else{
          this.cpferror = false
        }
        
        if(this.withdraw_money>this.meminfo.assets.withdrawable){
          //this.$snackbar.warning(this.$t('ktjebz'))
          this.withdraw_money_error = this.$t('ktjebz')
          return false;
        }
        if(this.withdraw_money<this.min_amount || this.withdraw_money>this.max_amount){
          //this.$snackbar.warning(this.$t('tkcgxe'))
          this.withdraw_money_error = this.$t('tkcgxe') + '(' +this.min_amount+' ~ '+this.max_amount+')'
          return false;
        }
        if(this.meminfo.deposited==0 && this.meminfo.invited==0){
          this.tipFree = true
          return false
        }
        this.withdraw_submit()
      },
      agree() {
        this.tipFree = false
        this.withdraw_submit()
      },
      withdraw_submit() {
        this.showprogress = true
        let paramObj = {
          member_id: this.getStorage('member_id'),
          money:this.withdraw_money,
          pix_type: this.pix_type,
          pix_no: this.pix_no,
          card_no: this.card_no,
          realname: this.realname,
        }
        this.$server.withdraw(paramObj).then((response) => {
          this.showprogress = false
          if(response.code==200){
            this.getmeminfo()
            //this.$snackbar.success(this.$t('tjcg'))
            this.showWaiting = true
            localStorage.setItem('pix_type', this.pix_type)
            localStorage.setItem('pix_no', this.pix_no)
            localStorage.setItem('card_no', this.card_no)
            localStorage.setItem('realname', this.realname)
          }else{
            this.$snackbar.warning(response.msg)
          }
        })
      },
      fanhui() {
        this.$emit('closeprofile')
      },
      qrcodeshow(param) {
        //console.log(param)
        this.select_amount = param[0]
        this.qr_code = param[1]
        this.qr_code_omit = param[2]
        this.erweima = param[3]
        this.showQrcode = true
      },
      depositcompleted() {
        this.$emit('closeprofile')
      },
      cashout_apply() {
        if(this.withdraw_money>this.meminfo.assets.available){
          //this.$snackbar.warning(this.$t('ktjebz'))
          this.withdraw_money_error = this.$t('ktjebz')
          return false;
        }
        if(this.withdraw_money<this.min_amount || this.withdraw_money>this.max_amount){
          //this.$snackbar.warning(this.$t('tkcgxe'))
          this.withdraw_money_error = this.$t('tkcgxe') + '(' +this.min_amount+' ~ '+this.max_amount+')'
          return false;
        }

        this.showprogress = true
        let paramObj = {
          player_id: this.getStorage('player_id'),
          amount:this.withdraw_money,
          remark: this.remark,
        }
        this.$server.cashout_apply(paramObj).then((response) => {
          this.showprogress = false
          if(response.code==200){
            this.getmeminfo()
            //this.$snackbar.success(this.$t('tjcg'))
            this.showWaiting = true
          }else{
            this.$snackbar.warning(response.msg)
          }
        })
      },
      doCopy: function (text) {
        this.$copyText(text, this.$refs.wallet).then( (e)=>{
          console.log(e)
          this.$snackbar.info(this.$t('fzcg'))
        }, function (e) {
          console.log(e)
        })
      },
    },
  };
</script>
