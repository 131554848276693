<template>
  <div>
    <v-tabs
      v-model="tab_record"
      centered
      background-color="box_bg"
    >
      <v-tab :href="`#tabrecord-1`">
        {{ $t('cunkuan') }}
      </v-tab>
      <v-tab :href="`#tabrecord-2`">
        {{ $t('tikuan') }}
      </v-tab>
      <v-tabs-items
        v-model="tab_record"
        class="app_bg pb-16 pb-sm-0"
      >
        <v-tab-item value="tabrecord-1">
          <div>
            <div class="px-4 py-2">
              <v-chip-group
                mandatory
                active-class="primary--text"
              >
                <v-chip
                  small
                  @click="changeDate1(29)"
                >
                  30 {{ $t('tian') }}
                </v-chip>
                <v-chip
                  small
                  @click="changeDate1(6)"
                >
                  7 {{ $t('tian') }}
                </v-chip>
                <v-chip
                  small
                  @click="changeDate1(2)"
                >
                  3 {{ $t('tian') }}
                </v-chip>
                <v-chip
                  small
                  @click="changeDate1(0)"
                >
                  {{ $t('jinri') }}
                </v-chip>

                <v-spacer />

                <v-chip
                  small
                  @click="select_date1 = !select_date1"
                >
                  <v-icon x-small>
                    mdi-filter
                  </v-icon>
                  {{ $t('sxrq') }}
                </v-chip>
              </v-chip-group>
              <div class="d-flex align-center text-right text-body-2 text--disabled">
                <v-chip outlined>
                  <v-icon
                    small
                    left
                  >
                    mdi-calendar-multiselect-outline
                  </v-icon>
                  {{ dateRangeText1 }}
                </v-chip>
              </div>
            </div>
            <v-divider style="border-color: rgba(0, 0, 0, .05);" />
            <div
              v-if="empty1"
              class="text-center text--disabled my-10"
            >
              <v-icon
                x-large
                class="text--disabled"
              >
                mdi-archive-search-outline
              </v-icon>
              <small class="d-block text-center">{{ $t('myrhjl') }}</small>
            </div>
            <v-list-item-group v-if="!empty1">
              <v-list-item
                v-for="(item, index) in log1"
                :key="'L1'+index"
                @click="show1(item)"
              >
                <v-list-item-action class="mr-3">
                  <small class="text--disabled">{{ item.created }}</small>
                </v-list-item-action>
                <v-list-item-content>
                  <div class="d-flex align-center">
                    <img
                      width="16"
                      src="../assets/img/icons/USD.png"
                    >
                    <strong class="mx-1">{{ item.amount }}</strong>
                  </div>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip
                    v-if="item.status==0"
                    small
                    outlined
                    color="grey"
                  >
                    {{ $t('daizhifu') }}
                  </v-chip>
                  <v-chip
                    v-if="item.status==1"
                    small
                    outlined
                    color="green"
                  >
                    {{ $t('wancheng') }}
                  </v-chip>
                  <v-chip
                    v-if="item.status==-1"
                    small
                    outlined
                    color="orange"
                  >
                    {{ $t('shibai') }}
                  </v-chip>
                </v-list-item-action>
                <v-list-item-icon class="ml-0">
                  <v-icon class="text--disabled">
                    mdi-chevron-right
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
            <div
              v-if="(page1-1)<pagecount1"
              class="text-center my-3"
              @click="depositlog"
            >
              <small>{{ $t('jzgd') }}</small>
            </div>
          </div>
        </v-tab-item>

        <v-tab-item value="tabrecord-2">
          <div>
            <div class="px-4 py-2">
              <v-chip-group
                mandatory
                active-class="primary--text"
              >
                <v-chip
                  small
                  @click="changeDate2(29)"
                >
                  30 {{ $t('tian') }}
                </v-chip>
                <v-chip
                  small
                  @click="changeDate2(6)"
                >
                  7 {{ $t('tian') }}
                </v-chip>
                <v-chip
                  small
                  @click="changeDate2(2)"
                >
                  3 {{ $t('tian') }}
                </v-chip>
                <v-chip
                  small
                  @click="changeDate2(0)"
                >
                  {{ $t('jinri') }}
                </v-chip>

                <v-spacer />

                <v-chip
                  small
                  @click="select_date2 = !select_date2"
                >
                  <v-icon x-small>
                    mdi-filter
                  </v-icon>
                  {{ $t('sxrq') }}
                </v-chip>
              </v-chip-group>
              <div class="d-flex align-center text-right text-body-2 text--disabled">
                <v-chip outlined>
                  <v-icon
                    small
                    left
                  >
                    mdi-calendar-multiselect-outline
                  </v-icon>
                  {{ dateRangeText2 }}
                </v-chip>
              </div>
            </div>
            <v-divider style="border-color: rgba(0, 0, 0, .05);" />
            <div
              v-if="empty2"
              class="text-center text--disabled my-10"
            >
              <v-icon
                x-large
                class="text--disabled"
              >
                mdi-archive-search-outline
              </v-icon>
              <small class="d-block text-center">{{ $t('myrhjl') }}</small>
            </div>
            <v-list-item-group v-if="!empty2">
              <v-list-item
                v-for="(item, index) in log2"
                :key="'L2'+index"
                @click="show2(item)"
              >
                <v-list-item-action class="mr-3">
                  <small class="text--disabled">{{ item.created }}</small>
                </v-list-item-action>
                <v-list-item-content>
                  <div class="d-flex align-center">
                    <img
                      width="16"
                      src="../assets/img/icons/USD.png"
                    >
                    <strong class="mx-1">{{ item.amount }}</strong>
                  </div>
                </v-list-item-content>
                <v-list-item-action>
                  <div
                    v-if="item.status==0"
                    class="text-center"
                  >
                    <v-chip
                      small
                      outlined
                      color="grey"
                    >
                      {{ $t('daitikuan') }}
                    </v-chip>
                  </div>
                  <v-chip
                    v-if="item.status==1"
                    small
                    outlined
                    color="green"
                  >
                    {{ $t('wancheng') }}
                  </v-chip>
                  <div
                    v-if="item.status<0"
                  >
                    <v-icon
                      v-if="item.remark!=''"
                      small
                      class="mr-2"
                    >
                      mdi-tooltip-text
                    </v-icon>
                    <v-chip
                      small
                      outlined
                      color="orange"
                    >
                      {{ $t('shibai') }}
                    </v-chip>
                  </div>
                </v-list-item-action>
                <v-list-item-icon class="ml-0">
                  <v-icon class="text--disabled">
                    mdi-chevron-right
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
            <div
              v-if="(page2-1)<pagecount2"
              class="text-center my-3"
              @click="withdrawlog"
            >
              <small>{{ $t('jzgd') }}</small>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>

    <template>
      <v-bottom-sheet
        v-model="details_deposit_log"
        max-width="550"
        content-class="rounded-t-lg"
      >
        <v-card
          class="rounded-t-lg pb-8 box_bg"
        >
          <v-app-bar
            elevate-on-scroll
            color="nav_bg"
          >
            <v-toolbar-title class="body-1">
              {{ $t('jlxq') }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn
              icon
              @click="details_deposit_log = !details_deposit_log"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-app-bar>
          <v-card-text
            v-if="details_deposit_log"
            ref="choose1"
            class="pa-0"
          >
            <v-list
              dense
              class="box_bg"
            >
              <v-list-item>
                <v-list-item-content>{{ $t('jine') }}</v-list-item-content>
                <v-list-item-action>
                  <div class="d-flex align-center">
                    <img
                      width="16"
                      src="../assets/img/icons/USD.png"
                    >
                    <strong class="orange--text mx-1">{{ choose1.amount }}</strong>
                    {{ choose1.currency }}
                  </div>
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>{{ $t('shijian') }}</v-list-item-content>
                <v-list-item-action>{{ choose1.created }}</v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>{{ $t('zhuangtai') }}</v-list-item-content>
                <v-list-item-action>
                  <v-chip
                    v-if="choose1.status==0"
                    small
                    outlined
                    color="grey"
                    class="ml-3"
                  >
                    {{ $t('daizhifu') }}
                  </v-chip>
                  <v-chip
                    v-if="choose1.status==1"
                    small
                    outlined
                    color="green"
                    class="ml-3"
                  >
                    {{ $t('wancheng') }}
                  </v-chip>
                  <v-chip
                    v-if="choose1.status==-1"
                    small
                    outlined
                    color="orange"
                    class="ml-3"
                  >
                    {{ $t('shibai') }}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>{{ $t('dingdan') }}</v-list-item-content>
                <v-list-item-action>
                  <div>
                    {{ choose1.orderno }}
                    <v-btn
                      icon
                      small
                      @click="doCopy1(''+choose1.orderno)"
                    >
                      <v-icon small>
                        mdi-content-copy
                      </v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>{{ $t('beizhu') }}</v-list-item-content>
                <v-list-item-action>{{ choose1.remark }}</v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-bottom-sheet>
    </template>

    <template>
      <v-bottom-sheet
        v-model="details_withdraw_log"
        max-width="550"
        content-class="rounded-t-lg"
      >
        <v-card
          class="rounded-t-lg pb-8 box_bg"
        >
          <v-app-bar
            elevate-on-scroll
            color="nav_bg"
          >
            <v-toolbar-title class="body-1">
              {{ $t('jlxq') }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn
              icon
              @click="details_withdraw_log = !details_withdraw_log"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-app-bar>
          <v-card-text
            v-if="details_withdraw_log"
            ref="choose2"
            class="pa-0"
          >
            <v-list
              dense
              class="box_bg"
            >
              <v-list-item>
                <v-list-item-content>{{ $t('jine') }}</v-list-item-content>
                <v-list-item-action>
                  <div class="d-flex align-center">
                    <img
                      width="16"
                      src="../assets/img/icons/USD.png"
                    >
                    <strong class="orange--text mx-1">{{ choose2.amount }}</strong>
                    {{ choose2.currency }}
                  </div>
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>{{ $t('shijian') }}</v-list-item-content>
                <v-list-item-action>{{ choose2.created }}</v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>{{ $t('zhuangtai') }}</v-list-item-content>
                <v-list-item-action>
                  <v-chip
                    v-if="choose2.status==0"
                    small
                    outlined
                    color="grey"
                    class="ml-3"
                  >
                    {{ $t('daitikuan') }}
                  </v-chip>
                  <v-chip
                    v-if="choose2.status==1"
                    small
                    outlined
                    color="green"
                    class="ml-3"
                  >
                    {{ $t('wancheng') }}
                  </v-chip>
                  <v-chip
                    v-if="choose2.status<0"
                    small
                    outlined
                    color="orange"
                    class="ml-3"
                  >
                    {{ $t('shibai') }}
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>{{ $t('dingdan') }}</v-list-item-content>
                <v-list-item-action>
                  <div>
                    {{ choose2.orderno }}
                    <v-btn
                      icon
                      small
                      @click="doCopy2(''+choose2.orderno)"
                    >
                      <v-icon small>
                        mdi-content-copy
                      </v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>{{ $t('beizhu') }}</v-list-item-content>
                <v-list-item-action>{{ choose2.remark }}</v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-bottom-sheet>
    </template>

    <template>
      <v-bottom-sheet
        v-model="select_date1"
        max-width="550"
        content-class="rounded-t-lg"
      >
        <v-card
          class="rounded-t-lg pb-8 box_bg"
        >
          <v-app-bar
            elevate-on-scroll
            color="nav_bg"
          >
            <v-btn
              text
              @click="select_date1 = !select_date1"
            >
              {{ $t('quxiao') }}
            </v-btn>
            <v-spacer />
            <v-btn
              depressed
              color="primary"
              @click="depositlog0"
            >
              {{ $t('queding') }}
            </v-btn>
          </v-app-bar>
          <v-card-text class="pa-3">
            <v-date-picker
              v-model="picker1"
              range
              no-title
              full-width
              color="primary"
              locale="pt-BR"
            />
          </v-card-text>
        </v-card>
      </v-bottom-sheet>
    </template>

    <template>
      <v-bottom-sheet
        v-model="select_date2"
        max-width="550"
        content-class="rounded-t-lg"
      >
        <v-card
          class="rounded-t-lg pb-8 box_bg"
        >
          <v-app-bar
            elevate-on-scroll
            color="nav_bg"
          >
            <v-btn
              text
              @click="select_date2 = !select_date2"
            >
              {{ $t('quxiao') }}
            </v-btn>
            <v-spacer />
            <v-btn
              depressed
              color="primary"
              @click="withdrawlog0"
            >
              {{ $t('queding') }}
            </v-btn>
          </v-app-bar>
          <v-card-text class="pa-3">
            <v-date-picker
              v-model="picker2"
              range
              no-title
              full-width
              color="primary"
              locale="pt-BR"
            />
          </v-card-text>
        </v-card>
      </v-bottom-sheet>
    </template>
  </div>
</template>
<script>
export default {
  name: 'Record',
  props: {
    tabrecord: {
      type: String,
      required: true
    }
  },
  data: () => ({
    tab_record: '',
    details_deposit_log: false,
    details_withdraw_log: false,
    details_bonus_log: false,

    select_date1: false,
    picker1: [],
    select_date2: false,
    picker2: [],
    select_date3: false,
    picker3: [],

    empty1: false,
    empty2: false,
    empty3: false,

    page1:1,
    pagecount1:1,
    log1:[],
    choose1:{},
    page2:1,
    pagecount2:1,
    log2:[],
    choose2:{},
    page3:1,
    pagecount3:1,
    log3:[],
    choose3:{},

  }),
  computed: {
    dateRangeText1:{
      get: function () {
        return this.$dayjs(this.picker1[0]).format('DD/MM/YYYY')+' ~ '+this.$dayjs(this.picker1[1]).format('DD/MM/YYYY')
      },
      set: function (newVal) {
      }
    },
    dateRangeText2:{
      get: function () {
        return this.$dayjs(this.picker2[0]).format('DD/MM/YYYY')+' ~ '+this.$dayjs(this.picker2[1]).format('DD/MM/YYYY')
      },
      set: function (newVal) {
      }
    },
    dateRangeText3:{
      get: function () {
        return this.$dayjs(this.picker3[0]).format('DD/MM/YYYY')+' ~ '+this.$dayjs(this.picker3[1]).format('DD/MM/YYYY')
      },
      set: function (newVal) {
      }
    }
  },
  watch:{
    dateRangeText1: {
      handler(newVal, oldVal) {
        this.picker1.sort()
      }
    },
    dateRangeText2: {
      handler(newVal, oldVal) {
        this.picker2.sort()
      }
    },
    dateRangeText3: {
      handler(newVal, oldVal) {
        this.picker3.sort()
      }
    },
  },
  created() {
    this.tab_record = this.tabrecord
    this.picker1 = [this.$dayjs().subtract(29, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]
    this.picker2 = [this.$dayjs().subtract(29, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]
    this.picker3 = [this.$dayjs().subtract(29, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]
  },
  mounted() {
    this.depositlog()
    this.withdrawlog()
    this.bonuslog()
  },
  methods: {
    changeDate1(d){
      this.page1 = 1
      this.pagecount1 = 1
      this.log1 = []
      this.picker1 = [this.$dayjs().subtract(d, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]
      this.depositlog()
    },
    changeDate2(d){
      this.page2 = 1
      this.pagecount2 = 1
      this.log2 = []
      this.picker2 = [this.$dayjs().subtract(d, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]
      this.withdrawlog()
    },
    changeDate3(d){
      this.page3 = 1
      this.pagecount3 = 1
      this.log3 = []
      this.picker3 = [this.$dayjs().subtract(d, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')]
      this.bonuslog()
    },
    depositlog0() {
      this.page1 = 1
      this.pagecount1 = 1
      this.log1 = []
      this.depositlog()
    },
    depositlog() {
      this.select_date1 = false
      if(this.page1>this.pagecount1){
        return false
      }
      let paramObj = {
        player_id: this.getStorage('player_id'),
        page:this.page1,
        startdate:this.picker1[0],
        enddate:this.picker1[1],
      }
      this.$server.record_deposit(paramObj).then((response) => {
        if(response.code==200){
          if(response.data.total==0){
            this.empty1 = true
          }
          this.pagecount1 = response.data.last_page
          if(response.data.data.length>0){
            this.empty1 = false
            this.log1.push(...response.data.data)
          }
          ++this.page1
        }else{
          this.$snackbar.error(response.msg)
        }
      })
    },
    withdrawlog0() {
      this.page2 = 1
      this.pagecount2 = 1
      this.log2 = []
      this.withdrawlog()
    },
    withdrawlog() {
      this.select_date2 = false
      if(this.page2>this.pagecount2){
        return false
      }
      let paramObj = {
        player_id: this.getStorage('player_id'),
        page:this.page2,
        startdate:this.picker2[0],
        enddate:this.picker2[1],
      }
      this.$server.record_withdraw(paramObj).then((response) => {
        if(response.code==200){
          if(response.data.total==0){
            this.empty2 = true
          }
          this.pagecount2 = response.data.last_page
          if(response.data.data.length>0){
            this.empty2 = false
            this.log2.push(...response.data.data)
          }
          ++this.page2
        }else{
          this.$snackbar.error(response.msg)
        }
      })
    },
    bonuslog0() {
      this.page3 = 1
      this.pagecount3 = 1
      this.log3 = []
      this.bonuslog()
    },
    bonuslog() {return false
      this.select_date3 = false
      if(this.page3>this.pagecount3){
        return false
      }
      let paramObj = {
        member_id: this.getStorage('member_id'),
        page:this.page3,
        startdate:this.picker3[0],
        enddate:this.picker3[1],
      }
      this.$server.record_award(paramObj).then((response) => {
        if(response.code==200){
          if(response.data.total==0){
            this.empty3 = true
          }
          this.pagecount3 = response.data.last_page
          if(response.data.data.length>0){
            this.empty3 = false
            this.log3.push(...response.data.data)
          }
          ++this.page3
        }else{
          this.$snackbar.error(response.msg)
        }
      })
    },
    withdraw_cancel(item){
      let paramObj = {
        member_id: this.getStorage('member_id'),
        id: item.id,
      }
      this.$server.withdraw_cancel(paramObj).then((response) => {
        if(response.code==200){
          this.details_withdraw_log = false
          this.withdrawlog0()
        }else{
          this.$snackbar.error(response.msg)
        }
      })
    },
    show1(item){
      this.details_deposit_log = true
      this.choose1 = item
    },
    show2(item){
      this.details_withdraw_log = true
      this.choose2 = item
    },
    show3(item){
      this.details_bonus_log = true
      this.choose3 = item
    },
    doCopy1: function (text) {
      this.$copyText(text, this.$refs.choose1).then( (e)=>{
        console.log(e)
      }, function (e) {
        console.log(e)
      })
    },
    doCopy2: function (text) {
      this.$copyText(text, this.$refs.choose2).then( (e)=>{
        console.log(e)
      }, function (e) {
        console.log(e)
      })
    },
    doCopy3: function (text) {
      this.$copyText(text, this.$refs.choose3).then( (e)=>{
        console.log(e)
      }, function (e) {
        console.log(e)
      })
    },
  },
};
</script>