const getStorage = function (name){
    if(sessionStorage.getItem('s')=='local'){
        return localStorage.getItem(name)
    }else{
        return sessionStorage.getItem(name)
    }
};
const setStorage = function (name, value){
    if(sessionStorage.getItem('s')=='local'){
        localStorage.setItem(name, value)
    }else{
        sessionStorage.setItem(name, value)
    }
};
const removeStorage = function (name){
    if(sessionStorage.getItem('s')=='local'){
        localStorage.removeItem(name)
    }else{
        sessionStorage.removeItem(name)
    }
};
const getDeviceId = function (){
    if(!localStorage.getItem('deviceId')){
        localStorage.setItem('deviceId', Math.random().toString(36).substring(2))
    }
    return localStorage.getItem('deviceId')
};
export {getStorage, setStorage, removeStorage, getDeviceId}