<template>
  <v-dialog
    v-model="showdialog"
    width="550"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card color="app_bg">
      <v-card-title class="nav_bg">
        <v-icon left>
          mdi-account-cog-outline
        </v-icon>
        {{ $t('grsz') }}
        <v-spacer />
        <v-btn
          icon
          @click="closemy"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text ref="my">
        <v-subheader>{{ $t('jcxx') }}</v-subheader>
        <v-list
          dense
          rounded
          color="box_bg"
        >
          <v-list-item
            @click="edit_avatar = true"
          >
            <v-list-item-content>
              {{ $t('touxiang') }}
            </v-list-item-content>
            <v-list-item-avatar size="48">
              <v-img :src="require('../assets/img/avatar/'+meminfo.avatar+'.png')" />
            </v-list-item-avatar>
            <v-list-item-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              UID
            </v-list-item-content>
            <v-list-item-action>
              {{ meminfo.id }}
            </v-list-item-action>
            <v-list-item-action>
              <v-btn
                icon
                small
                @click="doCopy(''+meminfo.id)"
              >
                <v-icon small>
                  mdi-content-copy
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <!--
          <v-list-item
            @click="edit_username = true"
          >
            <v-list-item-content>
              {{ $t('yhm') }}
            </v-list-item-content>
            <v-list-item-action>
              {{ meminfo.username }}
            </v-list-item-action>
            <v-list-item-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
          -->
        </v-list>

        <v-subheader>{{ $t('anquan') }}</v-subheader>
        <v-list
          dense
          rounded
          color="box_bg"
        >
          <v-list-item
            @click="edit_pwd = true"
          >
            <v-list-item-content>
              {{ $t('xgmm') }}
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="edit_avatar"
      max-width="400"
      scrollable
    >
      <v-card class="box_bg">
        <v-card-title class="text-subtitle-1">
          {{ $t('xztx') }}
        </v-card-title>
        <v-card-text>
          <v-row
            dense
            class="my-avatar-list"
          >
            <v-col
              v-for="n in 48"
              :key="n"
              cols="3"
            >
              <v-badge
                v-if="n==avatar_selected"
                bordered
                bottom
                overlap
                color="green"
                icon="mdi-check-bold"
                offset-x="20"
                offset-y="20"
                label=""
              >
                <v-avatar
                  size="72"
                  class="active"
                >
                  <v-img :src="require('../assets/img/avatar/'+n+'.png')" />
                </v-avatar>
              </v-badge>
              <v-avatar
                v-if="n!=avatar_selected"
                size="72"
                @click="selavatar(n)"
              >
                <v-img :src="require('../assets/img/avatar/'+n+'.png')" />
              </v-avatar>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="edit_avatar = false"
          >
            {{ $t('quxiao') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="editavatar"
          >
            {{ $t('qrtj') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="edit_username"
      max-width="300"
      scrollable
    >
      <v-card class="box_bg">
        <v-card-title class="text-subtitle-1">
          {{ $t('szyhm') }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="username"
            filled
            solo
            flat
            dense
            clearable
            autofocus
            background-color="nav_bg"
            hide-details
            :placeholder="$t('qsryzm')"
            class="grey--text text-body-2"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="edit_username = false"
          >
            {{ $t('quxiao') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            :disabled="username==null"
            @click="editusername"
          >
            {{ $t('qrtj') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="edit_phone"
      max-width="360"
      scrollable
    >
      <v-card class="box_bg">
        <v-card-title class="text-subtitle-1">
          {{ $t('yzsjh') }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model.number="phone"
            type="number"
            filled
            solo
            :prefix="meminfo.area_code"
            flat
            dense
            clearable
            autofocus
            background-color="nav_bg"
            hide-details
            :placeholder="$t('qsrsjh')"
            class="grey--text text-body-2 mb-2"
          />
          <v-row dense>
            <v-col cols="7">
              <v-text-field
                v-model.number="code"
                type="number"
                filled
                solo
                flat
                dense
                background-color="nav_bg"
                hide-details
                :placeholder="$t('qsryzm')"
                class="grey--text text-body-2 mt-1"
              />
            </v-col>
            <v-col
              cols="5"
              class="d-flex align-center justify-center pb-0"
            >
              <v-btn
                outlined
                color="grey"
                :disabled="codebtndisabled"
                @click="sendsms"
              >
                {{ codebtntext }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="edit_phone = false"
          >
            {{ $t('quxiao') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="editphone"
          >
            {{ $t('queding') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="edit_email"
      max-width="360"
      scrollable
    >
      <v-card class="box_bg">
        <v-card-title class="text-subtitle-1">
          {{ $t('yzyx') }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="email"
            filled
            solo
            flat
            dense
            clearable
            autofocus
            background-color="nav_bg"
            hide-details
            :placeholder="$t('qsryx')"
            class="grey--text text-body-2 mb-2"
          />
          <v-row dense>
            <v-col cols="7">
              <v-text-field
                v-model="emailcode"
                filled
                solo
                flat
                dense
                background-color="nav_bg"
                hide-details
                :placeholder="$t('qsryzm')"
                class="grey--text text-body-2 mt-1"
              />
            </v-col>
            <v-col
              cols="5"
              class="d-flex align-center justify-center pb-0"
            >
              <v-btn
                outlined
                color="grey"
                :disabled="emailcodebtndisabled"
                @click="sendemail"
              >
                {{ emailcodebtntext }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="edit_email = false"
          >
            {{ $t('quxiao') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="editemail"
          >
            {{ $t('queding') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="edit_realname"
      max-width="300"
      scrollable
    >
      <v-card class="box_bg">
        <v-card-title class="text-subtitle-1">
          {{ $t('szzsxm') }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="realname"
            filled
            solo
            flat
            dense
            clearable
            autofocus
            background-color="nav_bg"
            hide-details
            :placeholder="$t('qsrzsxm')"
            class="grey--text text-body-2"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="edit_realname = false"
          >
            {{ $t('quxiao') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            :disabled="realname==null"
            @click="editrealname"
          >
            {{ $t('qrtj') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="edit_birthday"
      max-width="300"
      scrollable
    >
      <v-card class="box_bg">
        <v-card-title class="text-subtitle-1">
          {{ $t('szsr') }}
        </v-card-title>
        <v-card-text>
          <v-menu
            ref="datemenu"
            v-model="datemenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="birthday"
                label="Birthday date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="birthday"
              no-title
              locale="pt-BR"
              color="primary"
              :active-picker.sync="activePicker"
              :max="(new Date().getFullYear()-18)+'-'+(new Date().getMonth()+1)+'-'+(new Date().getDate())"
              min="1950-01-01"
              @change="datesave"
            />
          </v-menu>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="edit_birthday = false"
          >
            {{ $t('quxiao') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            :disabled="birthday==null"
            @click="editbirthday"
          >
            {{ $t('qrtj') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="edit_cpf"
      max-width="300"
      scrollable
    >
      <v-card class="box_bg">
        <v-card-title class="text-subtitle-1">
          {{ $t('szcpf') }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="realname"
            filled
            solo
            flat
            dense
            clearable
            autofocus
            background-color="nav_bg"
            hide-details
            :placeholder="$t('qsrzsxm')"
            class="grey--text text-body-2"
          />
          <div class="mt-3 mb-1" />
          <v-text-field
            v-model="cpf"
            filled
            solo
            flat
            :hide-details="!cpferror"
            :error-messages="cpferror?$t('cpfgeshi'):''"
            dense
            clearable
            autofocus
            background-color="nav_bg"
            :placeholder="$t('qsrcpf')"
            class="grey--text text-body-2"
          />
          <div class="mt-3 mb-1" />
          <v-row dense>
            <v-col cols="7">
              <v-text-field
                v-model="verify_code"
                flat
                solo
                dense
                background-color="nav_bg"
              />
            </v-col>
            <v-col
              cols="5"
              class="d-flex"
            >
              <v-img
                :src="verifySrc"
                height="35"
                @click="refeshVerifySrc"
              />
            </v-col>
          </v-row>
          <div class="text-caption opacity-5 mt-2">
            {{ $t('cpftip2') }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="edit_cpf = false"
          >
            {{ $t('quxiao') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            :disabled="cpf==null || cpf=='' || realname==null || realname==''"
            @click="editcpf"
          >
            {{ $t('qrtj') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="edit_pwd"
      max-width="300"
      scrollable
    >
      <v-card class="box_bg">
        <v-card-title class="text-subtitle-1">
          {{ $t('xgmm') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="oldpwd"
                filled
                solo
                flat
                dense
                autofocus
                background-color="nav_bg"
                hide-details
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                :placeholder="$t('qsrjmm')"
                class="grey--text text-body-2"
                @click:append="show1 = !show1"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="newpwd"
                filled
                solo
                flat
                dense
                background-color="nav_bg"
                hide-details
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                :placeholder="$t('qsrxmm')"
                class="grey--text text-body-2"
                @click:append="show2 = !show2"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="newpwd2"
                filled
                solo
                flat
                dense
                background-color="nav_bg"
                hide-details
                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show3 ? 'text' : 'password'"
                :placeholder="$t('qqrxmm')"
                class="grey--text text-body-2"
                @click:append="show3 = !show3"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="edit_pwd = false"
          >
            {{ $t('quxiao') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="editpwd"
          >
            {{ $t('qrtj') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="claimfreebonus"
      max-width="400"
      scrollable
    >
      <v-card class="box_bg">
        <v-card-title class="text-subtitle-1">
          {{ $t('mfzs') }}
        </v-card-title>
        <v-card-text class="text-caption">
          <div class="mb-2">
            <v-badge
              inline
              content="1"
            />
            {{ $t('ywccpfsz') }}
            <v-icon
              small
              color="primary"
            >
              mdi-check
            </v-icon>
          </div>
          <div class="mb-2">
            <v-badge
              inline
              content="2"
            />
            <a
              v-if="ios"
              class="blue--text font-weight-medium text-decoration-none"
              @click="iosapp = true"
            >
              {{ $t('xiazai') }} APP 👈
            </a>
            <a
              v-else
              :href="whatsappurl"
              target="_blank"
              class="blue--text font-weight-medium text-decoration-none"
            >
              {{ $t('upappimg_rule1') }} 👈
            </a>
          </div>

          <div class="mb-2 d-flex justify-space-between">
            <v-badge
              inline
              content="3"
            />
            <span class="pl-1">
              {{ $t('xcnr') }}
            </span>
          </div>

          <div class="">
            <v-badge
              inline
              content="4"
            />

            <span class="orange--text">
              {{ $t('srwz') }}
            </span>
          </div>

          <div class="ml-8">
            {{ $t('qzxfsr') }}:<strong class="primary--text ml-1">YeloBet.com</strong>
            <v-text-field
              v-model="website"
              filled
              solo
              flat
              :hide-details="!websiteerror"
              :error-messages="websiteerror?$t('websiteerror'):''"
              dense
              clearable
              autofocus
              background-color="nav_bg"
              prefix="https://"
              class="grey--text text-body-2 mt-2"
            />
            <div class="mt-3 mb-1" />
            <v-text-field
              v-if="verify_email"
              v-model="email"
              filled
              solo
              flat
              dense
              background-color="nav_bg"
              hide-details
              :placeholder="$t('qsryx')"
              class="grey--text text-body-2 mb-2"
            />
            <v-row
              v-if="verify_email"
              dense
            >
              <v-col cols="7">
                <v-text-field
                  v-model="emailcode"
                  filled
                  solo
                  flat
                  dense
                  background-color="nav_bg"
                  hide-details
                  :placeholder="$t('qsryzm')"
                  class="grey--text text-body-2 mt-1"
                />
              </v-col>
              <v-col
                cols="5"
                class="d-flex align-center justify-center pb-0"
              >
                <v-btn
                  outlined
                  color="grey"
                  :disabled="emailcodebtndisabled"
                  @click="sendemail"
                >
                  {{ emailcodebtntext }}
                </v-btn>
              </v-col>
            </v-row>
            <v-btn
              dark
              color="green darken-1"
              depressed
              block
              class="mt-4"
              :disabled="website==null"
              :loading="loading"
              @dblclick="dblclick"
              @click="cpfbonus"
            >
              {{ $t('lingqu') }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-overlay :value="showprogress">
      <v-progress-circular
        indeterminate
      />
    </v-overlay>

    <template>
      <v-dialog
        v-model="iosapp"
        width="400"
        content-class="dialog-end"
      >
        <v-sheet
          dark
          color="nav_bg"
          class="pa-5 box-down-arrow rounded-lg"
        >
          <div class="position-relative">
            <v-btn
              fab
              icon
              small
              absolute
              top
              right
              color="grey darken-2"
              class="mr-n8"
              @click="iosapp = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>

          <h5 class="pb-3 text--secondary">
            {{ $t('azdsjzm') }}
          </h5>
          <div class="text-body-2 text--disabled">
            {{ $t('azdsjzmnr1') }}<v-icon
              color="blue"
              class="mx-1 mt-n2"
            >
              mdi-export-variant
            </v-icon>{{ $t('azdsjzmnr2') }}
          </div>
          <div class="mt-3 d-flex justify-space-around">
            <v-btn
              small
              outlined
              color="primary"
              @click="iosapp_help = true"
            >
              {{ $t('rhcz') }}?
            </v-btn>
            <v-btn
              small
              outlined
              color="primary"
              @click="iosapp_help = false"
            >
              {{ $t('wyaz') }}
            </v-btn>
          </div>
        </v-sheet>
      </v-dialog>

      <v-dialog
        v-model="iosapp_help"
        width="400"
      >
        <v-card
          color="box_bg"
        >
          <v-card-title
            dense
            class="text-body-1"
          >
            {{ $t('rhcz') }}?
            <v-spacer />
            <v-btn
              fab
              icon
              small
              color="grey darken-2"
              @click="iosapp_help = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-carousel
              hide-delimiters
              :continuous="false"
              height="100%"
            >
              <v-carousel-item>
                <img
                  width="100%"
                  src="../assets/img/pic/help-ios01.jpg"
                >
              </v-carousel-item>
              <v-carousel-item>
                <img
                  width="100%"
                  src="../assets/img/pic/help-ios02.jpg"
                >
              </v-carousel-item>
              <v-carousel-item>
                <img
                  width="100%"
                  src="../assets/img/pic/help-ios03.jpg"
                >
              </v-carousel-item>
            </v-carousel>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </v-dialog>
</template>

<script>
import store from "../store/"
export default {
  name: 'My',
  props: {
    
  },
  data: () => ({
    downloadurl: '',
    showdialog: true,
    edit_avatar: false,
    edit_username: false,
    edit_phone: false,
    edit_email: false,
    edit_realname: false,
    edit_birthday: false,
    edit_cpf: false,
    edit_pwd: false,
    meminfo:{
      id:0,
      avatar: 1
    },
    avatar_selected: 0,
    username: '',
    phone: '',
    email: '',
    realname: '',
    birthday: '',
    cpf: '',
    website: '',
    websiteerror: false,
    claimfreebonus: false,

    codebtndisabled: false,
    codebtntext: '',
    countdown1: 60,
    timer1: null,
    code: '',

    emailcodebtndisabled: false,
    emailcodebtntext: '',
    countdown2: 120,
    timer2: null,
    emailcode: '',

    activePicker: null,
    datemenu: false,

    show1: false,
    show2: false,
    show3: false,
    oldpwd: '',
    newpwd: '',
    newpwd2: '',

    cpferror: false,
    showprogress: false,
    ios: false,
    iosapp: false,
    iosapp_help: false,
    loading: false,

    verify_code: '',
    verifySrc: '',
    v_key: '',
    verify_email: false,
    whatsappurl: 'whatsapp://send?text=🎁Plataforma dando 12,77 se cadastrar no CPF💗Meusss ganhos de agora nem acredito 💸 corra vamos testar👉 https://euamojogos.com'
  }),
  computed: {

  },
  watch:{
    showdialog: function(nv,ov){
      console.log(nv,ov)
      this.closemy()
    },
    datemenu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    showprogress (val) {
      val && setTimeout(() => {
        this.showprogress = false
      }, 5000)
    },
  },
  created() {
    this.getmeminfo()
    if(!!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)){
      this.ios = true
    }
    this.downloadurl = this.$store.state.download

    if( (/iphone|ipod|ipad/.test( window.navigator.userAgent.toLowerCase() )) && (/safari/.test( window.navigator.userAgent.toLowerCase() ))==false ){
      this.whatsappurl = "pgb://"+encodeURIComponent(this.whatsappurl)
    }
  },
  mounted() {
    this.codebtntext = this.$t('hqyzm')
    this.emailcodebtntext = this.$t('hqyzm')
  },
  methods: {
    getmeminfo() {
      let paramObj = {
        player_id: this.getStorage('player_id'),
      }
      this.$server.getmeminfo(paramObj).then((response) => {
        if(response.code==200){
          this.meminfo = response.data
          this.avatar_selected = this.meminfo.avatar
          this.username = this.meminfo.username
          this.phone = this.meminfo.phone
          this.email = this.meminfo.email
          this.realname = this.meminfo.realname
          this.birthday = this.meminfo.birthday
          this.cpf = this.meminfo.cpf
          this.verify_email = response.data.verify_email
        }
      })
    },
    selavatar(n) {
      this.avatar_selected = n
    },
    editavatar() {
      this.showprogress = true
      let paramObj = {
        player_id: this.getStorage('player_id'),
        field: 'avatar',
        value: this.avatar_selected,
      }
      this.$server.modifymeminfo(paramObj).then((response) => {
        this.showprogress = false
        if(response.code==200){
          this.meminfo.avatar = this.avatar_selected
          this.edit_avatar = false
        }
      })
    },
    editusername() {
      this.showprogress = true
      let paramObj = {
        member_id: this.getStorage('member_id'),
        field: 'username',
        value: this.username,
      }
      this.$server.modifymeminfo(paramObj).then((response) => {
        this.showprogress = false
        if(response.code==200){
          this.meminfo.username = this.username
          this.edit_username = false
        }else{
          this.$snackbar.warning(response.msg)
        }
      })
    },
    editphone() {
      this.showprogress = true
      let paramObj = {
        member_id: this.getStorage('member_id'),
        field: 'phone',
        value: this.phone,
        code: this.code
      }
      this.$server.modifymeminfo(paramObj).then((response) => {
        this.showprogress = false
        if(response.code==200){
          this.meminfo.phone = this.phone
          this.edit_phone = false
        }else{
          this.$snackbar.warning(response.msg)
        }
      })
    },
    editemail() {
      this.showprogress = true
      let paramObj = {
        member_id: this.getStorage('member_id'),
        field: 'email',
        value: this.email,
        code: this.emailcode
      }
      this.$server.modifymeminfo(paramObj).then((response) => {
        this.showprogress = false
        if(response.code==200){
          this.meminfo.email = this.email
          this.edit_email = false
        }else{
          this.$snackbar.warning(response.msg)
        }
      })
    },
    sendsms() {
      var phone_regExp = /^\d{6,13}$/
      if (!phone_regExp.test(this.phone)) {
        this.$snackbar.warning(this.$t('sjhmgscw'))
        return false;
      }
      this.codebtndisabled = true
      let paramObj = {
        member_id: this.getStorage('member_id'),
        phone: this.phone
      }
      this.$server.sendsms_change(paramObj).then((response) => {
        if(response.code==200){
          this.timer1 = setInterval(() => {
            if (this.countdown1 > 0 && this.countdown1 <= 60) {
              this.countdown1--;
              if (this.countdown1 !== 0) {
                this.codebtntext = this.$t('hqyzm') + " (" + this.countdown1 + ")"
              } else {
                this.clear1()
              }
            }
          }, 1000)
        }else{
          this.codebtndisabled = false
          this.$snackbar.warning(this.$t('fssb'))
        }
      })
    },
    clear1() {
      clearInterval(this.timer1)
      this.countdown1 = 60
      this.timer1 = null
      this.codebtndisabled = false
      this.codebtntext = this.$t('hqyzm')
    },
    sendemail() {
      var email_regExp = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      if (!email_regExp.test(this.email)) {
        this.$snackbar.warning(this.$t('yxgscw'))
        return false;
      }
      this.emailcodebtndisabled = true
      let paramObj = {
        member_id: this.getStorage('member_id'),
        email: this.email
      }
      this.$server.sendemail_change(paramObj).then((response) => {
        if(response.code==200){
          this.timer2 = setInterval(() => {
            if (this.countdown2 > 0 && this.countdown2 <= 120) {
              this.countdown2--;
              if (this.countdown2 !== 0) {
                this.emailcodebtntext = this.$t('hqyzm') + " (" + this.countdown2 + ")"
              } else {
                this.clear2()
              }
            }
          }, 1000)
        }else{
          this.emailcodebtndisabled = false
          this.$snackbar.warning(this.$t('fssb')+response.msg)
        }
      })
    },
    clear2() {
      clearInterval(this.timer2)
      this.countdown2 = 120
      this.timer2 = null
      this.emailcodebtndisabled = false
      this.emailcodebtntext = this.$t('hqyzm')
    },
    editrealname() {
      this.showprogress = true
      let paramObj = {
        member_id: this.getStorage('member_id'),
        field: 'realname',
        value: this.realname,
      }
      this.$server.modifymeminfo(paramObj).then((response) => {
        this.showprogress = false
        if(response.code==200){
          this.meminfo.realname = this.realname
          this.edit_realname = false
        }else{
          this.$snackbar.warning(response.msg)
        }
      })
    },
    datesave (date) {
      this.$refs.datemenu.save(date)
    },
    editbirthday() {
      this.showprogress = true
      let paramObj = {
        member_id: this.getStorage('member_id'),
        field: 'birthday',
        value: this.birthday,
      }
      this.$server.modifymeminfo(paramObj).then((response) => {
        this.showprogress = false
        if(response.code==200){
          this.meminfo.birthday = this.birthday
          this.edit_birthday = false
        }else{
          this.$snackbar.warning(response.msg)
        }
      })
    },
    editcpf() {
      var cpf_regExp = /^\d{11,11}$/
      if (!cpf_regExp.test(this.cpf)) {
        this.cpferror = true
        return false
      }
      
      this.editrealname()

      this.showprogress = true
      let paramObj = {
        member_id: this.getStorage('member_id'),
        field: 'cpf',
        value: this.cpf,
        v_key: this.v_key,
        verify_code: this.verify_code,
      }
      this.$server.modifymeminfo(paramObj).then((response) => {
        this.showprogress = false
        if(response.code==200){
          this.meminfo.cpf = this.cpf
          this.edit_cpf = false
          if(response.data.bonus777){
            this.claimfreebonus = true
          }
        }else{
          this.$snackbar.warning(response.msg)
        }
      })
    },
    cpfbonus() {
      this.loading = true
      if(this.website.toLowerCase()!='YeloBet.com'){
        this.websiteerror = true
        this.loading = false
        return false
      }
      let paramObj = {
        member_id: this.getStorage('member_id'),
        email: this.email,
        emailcode: this.emailcode,
      }
      this.$server.cpfbonus(paramObj).then((response) => {
        if(response.code==200){
          this.claimfreebonus = false
          this.getmeminfo()
          store.commit('balance', this.meminfo.total_balance)
        }else{
          this.$snackbar.warning(response.msg)
        }
        this.loading = false
      })
    },
    editpwd() {
      if(this.newpwd!=this.newpwd2){
        this.$snackbar.warning(this.$t('lcmmbyz'))
        return false
      }
      this.showprogress = true
      let paramObj = {
        player_id: this.getStorage('player_id'),
        oldpwd: this.oldpwd,
        newpwd: this.newpwd,
      }
      this.$server.changepwd(paramObj).then((response) => {
        this.showprogress = false
        if(response.code==200){
          this.oldpwd = ''
          this.newpwd = ''
          this.edit_pwd = false
          this.$snackbar.warning(this.$t('xgcg'))
        }else{
          this.$snackbar.warning(response.msg)
        }
      })
    },
    editrealname_allow() {
      if(!this.meminfo.realname){
        this.edit_realname = true
      }
    },
    editcpf_allow() {
      if(!this.meminfo.cpf){
        this.verify()
        this.edit_cpf = true
      }else{
        this.bonus777()
      }
    },
    verify() {
      let paramObj = {

      }
      this.$server.verify(paramObj).then((response) => {
        if(response.code==200){
          this.verifySrc = response.data.data.img
          this.v_key = response.data.data.key
        }else{
          
        }
      })
    },
    refeshVerifySrc() {
      this.verify()
    },
    bonus777() {
      let paramObj = {
        member_id: this.getStorage('member_id')
      }
      this.$server.bonus777(paramObj).then((response) => {
        if(response.code==200){
          if(response.data.disabled==false){
            this.claimfreebonus = true
          }
        }else{
          
        }
      })
    },
    dblclick() {
      console.log('dblclick')
    },
    closemy() {
      this.showdialog = false
      this.$emit('closemy')
    },
    doCopy: function (text) {
      this.$copyText(text, this.$refs.my).then( (e)=>{
        console.log(e)
        this.$snackbar.info(this.$t('fzcg'))
      }, function (e) {
        console.log(e)
      })
    },
  },
};
</script>

<style>
  .my-avatar-list .v-avatar{
    border-radius: 50%;
    border: 3px solid transparent;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  .my-avatar-list .v-avatar:hover{
    border-color: orange;
  }
  .my-avatar-list .v-avatar.active {
    border-color: #4CAF50;
  }

</style>