export default {

	ckcz: 'Deposit',
	sxsjj: '3h Click',
	tgzq: 'Refer & Earn Money',
	
	dating: 'Home',
	rmyx: 'Hot Game',
	pgyx: 'PG Soft',
	slots: 'Slots',
	scj: 'Favorites',
	yhhd: 'Promotions',
	vipgb: 'VIP Club',
	help: 'Support',
	xitong: 'Systems',
	qianse: 'Light',
	shense: 'Dark',

	j30tck: 'Total deposits in the last 30 days',
	jjzx: 'Deposit Bonus',
	grsz: 'Account',
	tuichu: 'Sign out',

	shouye: 'Home',
	youhui: 'Promotions',
	tuiguang: 'Earn',
	kefu: 'Support',

	denglu: 'Login',
	zhuce: 'Register',
	yqm: 'Referral code',
	sryqm: 'Enter referral code',
	rgmyzwxtx: "Leave it blank if you don't have one",
	shoujihao: 'Mobile number',
	qsrsjh: 'Enter mobile number',
	qsryhm: 'Enter UserName',
	mima: 'Password',
	qsrmm: 'Enter password',
	wjmm: 'Forgot password',
	myzh: "Don't have an account yet?",
	yyzh: 'Already have an account?',
	czmm: 'Reset Password',
	yzsjh: 'Verify mobile number',
	qsryzm: 'Verification code',
	hqyzm: 'Send',
	next: 'Next',
	szxmm: 'Set new password',
	szmm: 'Set password',
	qrmm: 'Confirm password',
	czcg: 'Reset successful',
	mmszcg: 'Password was set successfully',
	sjhmgscw: 'Mobile number is not in a valid format',
	yxgscw: 'Email is not in a valid format',
	zcsb: 'Error!',
	hmycz: 'Mobile number registered, please login.',
	dlsb: 'Error!',
	dlsbmmcw: 'Login failed, invalid mobile number or password',
  	dlsbbcz: 'Mobile number does not exist',
	fssb: 'Error!',
	yzsb: 'Error!',
	czmmsb: 'Error!',
	qsrxmm: 'Enter a new password',
	qqrxmm: 'Please confirm the new password',
	mmbyz: 'New password does not match',
	ktjebz: 'Insufficient funds to withdraw',
	ckcgxe: 'Observe the minimum and maximum deposit values',
	tkcgxe: 'Observe the minimum and maximum withdrawal values',

	jcxx: 'Account Info',
	touxiang: 'Avatar',
	yhm: 'Username',
	sjh: 'Mobile number',
	wsz: 'None',
	sfxx: 'Personal Info',

	zsxm: 'Full Name',
	xing: 'Last Name',
	ming: 'First Name',
	shengri: 'Date Of Birth',
	anquan: 'Security',
	xgmm: 'Change Password',
	xztx: 'Avatar',
	quxiao: 'Cancel',
	qrtj: 'Save',
	szyhm: 'Username',
	yzyx: 'Email Verification',
	qsryx: 'Please enter your Email',
    szzsxm: 'Full Name',
	szxing: 'Last Name',
	szming: 'First Name',
    qsrzsxm: 'Please enter your Full Name',
	qsrxing: 'Please enter your Last Name',
	qsrming: 'Please enter your First Name',
	szsr: 'Date Of Birth',
	szcpf: 'Register CPF',
	qsrcpf: 'Please enter your CPF',
	qsrjmm: 'Please enter your previous password',
	lcmmbyz: 'New password does not match',
	xgcg: 'Change successful',

	qianbao: 'Cashier',
	jiangjin: 'Bonus',
	jilu: 'History',
	cunkuan: 'Deposit',
	tikuan: 'Withdraw',
	myrhjl: 'No Data',
	tian: 'Days',
	jinri: 'Today',
	sxrq: 'Date Selection',
	queding: 'Confirm',
	daizhifu: 'Unsuccessful',
	wancheng: 'Successful',
	shibai: 'Failure',
	qxtk: 'Cancel',
	yqbjzwb: 'All info loaded',
	jzgd: 'More',
	daitikuan: 'Pending',
	jlxq: 'Details',
	jine: 'Amount',
	shijian: 'Time',
	zhuangtai: 'Status',
	dingdan: 'Transaction No.', 
	beizhu: 'Comment',
	leixing: 'Type',
	quanding: 'Confirm',

	type_bonus: 'Deposit Bonus',
	type_hour3award: 'Hourly Bonus',
	type_golden_pig: 'Piggy Bank',
	type_weekly_deposit_bonus: 'Daily Streak',
	type_daily_rebate: 'Wagering Cashback',
	type_invitation_bonus: 'Referral Bonus',
	type_bet_commission: 'Wagering Commission',
	type_deposit_commission: 'Deposit Commission',
	scjjmrcs: 'Deposit Bonus',

	wdjj: 'Active Bonuses',
	lsjl: 'History',
	ljlq: 'Total Claimed',
	daijiesuo: 'Pending to unblock',
	rwjd: 'Progress',
	jxz: 'In progress',
	fangqi: 'Abandon',
	lqjj: 'Collect',
	hdlb: 'Bonus List',
	gzsm: 'Terms and Conditions',
	lsyq: 'Wagering Requirements',
	canjia: 'Participate',
	yswwcdjjrw: 'A bonus is currently active',
	tszncjyg: 'To activate the next bonus, you must complete or abandon the current bonus.',
	zdl: 'Okay',
	qrfqgjjrw: 'Are you sure you want to abandon?',
	fqhgjjrwsx: 'An abandoned bonus will not be recoverable and all progress will be lost.',
	queren: 'Confirm',
	gz1: 'You can only participate in one bonus program at a time',
	gz2: 'To activate the next bonus, you must complete or abandon the current bonus.',
	gz3: 'Bonus program participants must meet the amount and wagering requirements',
	gz4: 'Bonuses are paid directly into your Cash Balance, which you can use to play on YeloBet.com or withdraw',
	cjjjhd: 'Participate in the bonus program',
	hdmc: 'Bonus type',
	kyye: 'Total Balance',
	qbye: 'Wallet Balance',
	cyje: 'Base value',
	qsrje: 'Enter the amount',
	jjbl: 'Bonus multiplier',
	jjje: 'Bonus amount',
	wctzedhkhdjj: 'Bonuses can be collected after meeting the wagering requirements',
	cglq: 'Collection success',
	guanbi: 'Close',
	kyyebz: 'Insufficient available balance',
	qbyebz: 'Insufficient wallet balance',
	cjjezsw: 'Minimum amount is',
	qdyezh: 'Confirm the transfer of the game balance back to the wallet',
	yjgj: 'Collect',

	zffs: 'Payment Method',
	ckje: 'Amount',
	zengsong: 'Bonus',
	cjsczs100: 'Participate in a 150% deposit bonus on your first deposit.',
	tkfs: 'Withdrawal Method',
	zjlx: 'Card type',
	zjid: 'CPF',
	pixzhlx: 'PIX key type',
	zhanghao: 'PIX key',
	ktje: 'Cash Balance',
	mctkxe: 'Withdrawal limit',
	tksqytj: 'Your withdrawal is being processed',
	fanhui: 'Close',  
	jjsm: 'Terms and Conditions',
	jjsmnr: 'xxxxxxxxxxxxx',
	zmfk: 'How do I pay',
	zmfk2: 'Open your bank or payment mobile app, navigate to the PIX Area, select "Pix Copy and Paste" or "Read QR code" and complete the payment!',
	fzpixcode: 'Copy PIX CODE',
	fzcg: 'Copy successfully',
	ckmx: 'Deposit details',
	qz10fznfk: 'Please complete the payment within 10 minutes',
	smewm: 'Scan the QR code',
	yxyezdgj: 'Collecting in-game money',
	tjcg: 'Submitted Successfully',
	wyzfcg: 'Payment Completed',

	tjbzq: 'Refer and Earn Money',
	yqpybhd: 'Refer A Friend And You Can Earn',
	yibiaopan: 'Dashboard',
	tdgl: 'My Team',
	tzyj: 'Wagering Commission',
	ckyj: 'Deposit Commission',
	tjjj: 'Referral Bonus',
	wdljyj: 'Commission Overview',
	yqjj: 'Referral Bonus',
	yqpyzq: 'Share Links & Earn Money!',
	yqlj: 'Your referral link',
	wdyjbl: 'My commission rate',
	wddj: 'My referral level',
	yongjin: 'Commission',
	zkqb: 'View more',
	zhedie: 'Fold',
	sjtj: 'Level progress',
	yxyq: 'Depositors',
	ljyj: 'Accumulative Commission',
	yqjl: 'Referral Bonus',
	zcbljckdd: 'Register and make cumulative deposits of',
	tgndyqlj: 'For every player who registers through your referral link and makes a deposit, you get a referral bonus. The more people you invite, the more bonuses you get!',
	yaoqing: 'Refer ',
	ygyxyh: '1 active player',
	zuigao: 'Maximum ',
	yonghu: 'Players',
	chaoguo: 'over',
	touzhu: 'Wagering',
	yhckcg: 'Player deposits over R$ 200',
	yhtzcg: 'Player wagers over R$ 2.000',
	mryqrs: 'Daily referrals',
	khdjjmr: 'Bonus/Per invitee',
	tghyjb: 'Referral levels',
	ljyjjkl: 'Accumulative commission only calculates the deposit commission and wagering commission',
	dengji: 'Level',
	yjbl: 'Commission rate',
	guanxi: 'Tiers',
	yqjjpm: 'Referral Bonus Ranking',
	yjjjpm: 'Commission Ranking',
	ktyj: 'Receivable commission',
	lingqu: 'Collect',
	ytyj: 'Collected commissions',
	zyqs: 'Multilevel',
	ren: 'Players',
	yjrs: 'Number of Tier 1',
	ejrs: 'Number of Tier 2',
	sjrs: 'Number of Tier 3',
	quanbu: 'Total',
	zuori: 'Yesterday',
	benzhou: 'This week',
	benyue: 'This month',
	shangyue: 'Last month',
	zyqjj: 'Total referral bonus',
	ztzyj: 'Total wagering commission',
	zckyj: 'Total deposit commission',
	ztjrs: 'Multilevel',
	yjyj: 'Tier 1',
	ejyj: 'Tier 2',
	sjyj: 'Tier 3',
	qbdj: 'All tiers',
	zcrq: 'Registration date',
	sousuo: 'Search',
	zcke: 'Total deposits',
	ztze: 'Total wagerings',
	yjrq: 'Date',
	tzrs: 'Number of bettors',
	lqsj: 'Collection time',
	ckrs: 'Number of depositors',
	yxyqjj: 'Referral Bonus',
	jjrq: 'Date',
	zcyhs: 'Number of registrants',

	qbvipdj: 'All VIP Levels',
	dqdj: 'Current Level',
	j30tljck: 'Total deposits in the last 30 days',
	rgysjd: 'To level up to',
	nxyzck: 'Still need to deposit',
	daojishi: 'Countdown',
	cxg: 'Piggy Bank',
	zckjj: 'Daily Streak',
	zckjj_sub: 'Up to 150% Weekly Bonus',
	rfsjj: 'Cashback',
	lhjfs: 'Slots Cashback',
	zrfs: 'Live Casino Cashback',
	yidacheng: 'Achieved',
	dengjiyaoqiu: 'Requirement',
	sstckxdd: 'Deposits for the last 30 days',
	hxyzck: 'Still need to deposit',
	quanyi: 'VIP Benefits',
	laohuji: 'Slots',
	zhenren: 'Live Casino',

	hdsj: 'Promotional Period',
	paixu: 'Sort by',
	wdgd: 'My Tickets',
	tjxgd: 'Submit a new ticket',
	cjwt: 'FAQ',
	gengduo: 'more',
	youbz: 'useful',
	meiyong: 'useless',
	sfzxkf: 'I want to contact the support',

	qbtz: 'Players',
	dayingjia: 'Big Win',
	gbdj: 'Lucky Bets',
	youxi: 'Game',
	wanjia: 'Player',
	beishu: 'Multiplier',
	shuying: 'Payout',

	suoding: 'Blocked',
	wxts: 'Tips',
	ddcnlq: 'Available for VIP level 2 and above',
	mzznlqyc: 'Only one claim per week is allowed',

	cjwd: 'FAQ',
	cpfgeshi: 'CPF must be 11 digits',
	kyyetip: 'Before withdrawal, the deposit amount needs to be fully wagered',
	mfzs: 'Free Sign-up Bonus of',
  	mfzs2: 'Register your CPF and get free R$ 7,77 bonus!',
	cpftip1: 'Get R$2 cash back immediately after your first deposit',
	cpftip2: "Can't change after registration!",

	riqi: 'Date',
	ewjl: 'Extra Reward',
	zhouyi: 'Monday',
	zhouer: 'Tuesday',
	zhousan: 'Wednesday',
	zhousi: 'Thursday',
	zhouwu: 'Friday',
	zhouliu: 'Saturday',
	zhouri: 'Sunday',
	yilingqu: 'Collected',
	weilingqu: 'Missed',
	zckjj1: "For every R$ 500 you deposit during a week, you'll receive a daily bonuses and extra rewards during the next week!",
	zckjj2: 'Log in and claim your daily reward each day. A daily bonus will no longer be available the next day if you miss it!',
	zckjj3: 'Receive an extra R$ 18 on Sundays if you claim your bonus more than 5 days of the week and a double bonus on Thursdays!',
	zckjj4: 'Your bonus also increases according to your VIP Club Level!',
	szlj: "Last Week's Accumulated Deposits",
	weiman: 'Less than',
	yiman: 'Over',

	ywccpfsz: 'Completed CPF Registration',
	xiazai: 'Download',
	xiazai_tip_text: 'Best gaming experience',
	srwz: 'Enter the correct official website',
	qzxfsr: 'Please enter below',
	websiteerror: 'Incorrect entry, please enter: YeloBet.com',
	xcnr: 'Post the webpage screenshot on Facebook and Public group',

	newtip_biaoti: 'Best Experience',
	newtip_neirong: 'Please install YeloBet.com™ APP for the best gaming experience',
	
	reg_freebonus: 'Get R$ 12.77 Register Now!',

	azdsjzm: 'Install YeloBet.com™ APP',
	azdsjzmnr1: 'Click',
	azdsjzmnr2: 'this icon to install YeloBet.com APP to your phone in Safari',
	rhcz: 'How to',
	wyaz: 'I Installed',

	wckts: 'Non-deposit players can withdraw up to R$30 using the free sign-up bonus, the remaining balance is deducted.',
	wckts2: 'Post the webpage screenshot to Facebook and Public group, your withdrawal will be processed faster!',

	scjtbt: 'Post Your invite LInk in a Facebook Public Group',
	scjt: 'Upload screenshot',
  lq_x: 'Receive a',
  x_jj: 'bonus.',
	jlck: 'Example',
	daishenhe: 'In review',
  yifafang: 'Paid',
  weitongguo: 'Reject',
	tiezilianjie: 'Post Link',
	tijiao: 'submit',
	upimg_a01: 'Please write a post promoting our website using your referral link,',
	upimg_a02: '(Example: Introducing new players sign-up bonus of R$ 7.77)',
	upimg_a03: 'Post to any Facebook Public group.',
	guize: 'Rules',
	upimg_b01: 'We will review your uploaded screenshot.',
	upimg_b02: 'The review will be completed within 1 hour.',
	upimg_b03: 'We will reward you with R$ 2.5 per post after you pass the review, 5 times daily.',
	upimg_b04: 'Please make sure that each screenshot you upload is a new Facebook post',
	zs1zt: 'Please upload at least 1 image',
	tgljbnwk: 'Please fill in the post link',
	tgljycz: 'The link to the post already exists',
	xctjsj: 'Please post on facebook 1 hour later and upload a new screenshot.',
	xctjsj2: 'You can send whatsapp messages again after 1 hour',
	ytzntjyc: 'You have uploaded today. Only 5 times a day.',
	ytzntj2c: 'You have uploaded 5 times today, only 5 times a day.',
	ytzntj2c2: 'You have uploaded 2 times today, only 2 times a day.',
	yjfz: 'Quick copy',
	ljgscw: 'Please enter the correct post link',
	xzwfsy: 'Not available now, plz post and upload between 09:00 and 24:00',

	post_ad1: 'Post to Facebook group',
	shezhi: 'Setting',
	shangchuan: 'Upload',
	wcCPFsz: 'Register your CPF',
  upappimg: 'Share via Whatsapp to get bonus',
  upappimg2: 'Upload screenshot',
  shsb_cxsc: 'Review failed, upload again.',
  upappimg_rule1: 'Click on the Whatsapp👇 below to send your invitation link to at least 20 friends.',  
  upappimg_rule2: "Please upload a Screenshot of whatsapp after successful sending.",  
  upappimg_rule3: 'After publishing, please upload a screenshot of your facebook post to claim R$5.77 bonus',

	post_tg: 'Share to Whatsapp',
	post_tg_img: 'Share via Whatsapp to get R$ 6',
	post_tg_end: 'Just 1 upload per day, you can continue sharing tomorrow',
  post_tg_rule1: 'Click on the Whatsapp👇 below to send your invitation link to at least 20 friends or Group.',
  post_tg_rule2: 'Please upload a Screenshot of Whatsapp after successful sending.',
	post_tg_rule2a: 'We will review the screenshot you uploaded within 1 hour',
	post_tg_rule2b: 'We will reward you with R$3 , 2 times daily.',
	post_tg_rule2c: 'Please ensure that the picture uploaded everyday is a new share.',

	post_fb_tt: 'Share a Post + R$ 10000 BIg Giveaway',
  post_fb_tt2: 'Share a post for a chance to Win Money + Samsung and iPhone 15',
  huodong: 'Event',
  kaijiangjieguo: 'Winners',
  canyurenshu: 'Total participants',
  huodongshijian: 'We will announce the winners list on 20.Dec.2023',
  post_fb_bonus1: '1st prize: R$ 2000',
  post_fb_bonus2: '2nd prize: R$ 500',
  post_fb_bonus3: '3rd prize: R$ 1000',
  post_fb_bonus4: '4-10th prize: R$ 100',
  post_fb_bonus5: '11-100th prize: R$ 50',
  post_fb_bonus6: '101-200th prize: R$ 20',
  post_fb_rule1: 'Click 👇 to share the post and reply to comments + @your friends',
  post_fb_rule2: 'Submit your shared screenshot',
  post_fb_rule3: 'We will review your uploaded screenshot.',
  post_fb_rule4: 'Waiting for the lucky draw list to be announced on 20/12/2023',
	post_fb_btn: 'Facebook Lucky draw',
	daikaijiang: 'to be drawn',
	zhongjiang: 'winning',
	weizhongjiang: 'Not winning',
	post_ad3: 'Win Iphone & Samsung & Money',

	dlcgts: 'You have successfully logged in.',
  ckts: 'Deposit and enjoy playing!',
  sc100jj: 'Deposit now and get golden eggs tomorrow',
	ljck: 'Deposit now',

	mtsdyj: "You can receive today's commission tomorrow",
	ni: 'You',
	yjms: 'Commission model',
	cengji: 'Tier',
	fsxxzqjj: 'Send SMS message and Earn rewards',
	bwmfs: 'Click on the number below👇 to help us send marketing messages',
	mwc10t: 'Send 10 messages, you can earn',
	yjfs: 'Already sent',
	ytzntj2c3: '2 SMS marketing tasks in a day',

	sendws: 'Make money by sending SMS',

	browser_tip1: 'Method 1',
	browser_tip2: 'Method II',
	browser_tip_dianji: 'click',
	browser_tip_xuanze: 'option',
	browser_tip1_1: 'Open menu bar',
	browser_tip1_2: 'Open in the browser',
	browser_tip2_1: 'Copy',
	browser_tip2_2: 'Paste into browser to open',

	mrzjdbtn: 'Lucky Golden Eggs',
	mrzjdbtn_i: 'Daily Lucky Golden Egg',
	mrzjd: 'Lucky Golden Eggs Summoning',
	mrzjd2: 'Share your rewards to your friends via WhatsApp',
	hyjhcs1: 'You have',
	hyjhcs2: 'chances today',
	mrzjdgz1: 'Deposit today ≥ R$20 , Get 1 Golden Egg tomorrow',
	mrzjdgz2: 'Deposit today ≥ R$30 , Get 2 Golden Eggs tomorrow',
	mrzjdgz3: 'Deposit today ≥ R$50, Get 3 Golden Eggs tomorrow',
	zrck: "Yesterday's deposit",

	fbspyqjj: "Upload a video on Kwai, Youtube",
	mtlqjl: "Get R$6  reward everyday",
	xzrhfb: "Download and Save the Video to your album, then publish Video  to your Kwai or Youtube (Do not upload SLOTS videos to tiktok, they will be deleted immediately by tiktok)",
	fsytpl: "After publishing, please copy the text below and leave a comment, and edit your profile -> Bio",
	scjtlink: "Upload your video Screenshot and Video Shareing LINK. If your video is approved, you will be rewarded R$1, 6 times per day",
	bzfbrq: "Please ensure that the publishing time is today.",
	mydymjl: "Based on the views of each video, we will select the first place every month and receive a reward of R$2000.",
	bcspxz: "Download video",
	upvideo: 'Earn R$6 - Upload and publish video!',

	giveaway: 'Make your first deposit and we will pay R$2 to your PIX, Check Now',

	license_nav: 'Gaming License',
	license_tt: 'Curacao License',
  license_subtt: 'BonusPG.com – Responsible Casino',
  license_nei: 'The Curacao Gaming License is one of the most popular e-gaming licenses in the world. The major software providers are licensed by the Curacao Gaming Control Board. A license covers all games such as online casinos, slots, sports betting, and electronic games. Athletics, lotteries, and games of skill and chance. This licensing agency is supported by the government of Curacao and was established to ensure that all operators comply with the regulatory framework and code of conduct. The following is the description of the platform license (BonusPG.com). Please do not steal and offenders will be prosecuted.',

	freespins_t1: 'Play Free Spins',
  freespins_t2: 'now',
  freespins_t3a: 'You have',
  freespins_t3b: 'free spins in total',
	freespins_t4: 'Free Spins Available now',
  freespins_t5: 'The remaining Free Spins can be used Tomorrow',
  freespins_tip1: 'How do Free Spins Bonuses Work?',
  freespins_tip2: 'Free spins on a slot game that you can enjoy without betting any of your real money on the outcome.  Free Spins are awarded to players in certain situations, such as a reward for a completed Challenge, or as part of a promotion.',
  freespins_tip3: "*Receive varying numbers of Free Spins daily based on the amount of each of your First 5 deposits. You can use half of today's free spins immediately and save the remaining half for use tomorrow.",
  freespins_tip4: 'Every Deposit ≥ R$10, Get 1 Free Spins',
  freespins_tip5: 'Every Deposit ≥ R$50, Get 5 Free Spins',
  freespins_tip6: 'Every Deposit ≥ R$100, Get 12 Free Spins',
  freespins_tip7: 'Free Spins are for use on specified eligible games, with winnings converted into a Casino Instant real money once every free spin is completed.',
  freespins_tip8: 'Now free spins  only available on PG -FORTUNE TIGER, FORTUNE RABBIT, FORTUNE MOUSE, FORTUNE OX',
	freespins_tip9: 'Free spins are played with the same total bet of R$0.4',
	freespins_btn: 'Enter to Play Free Spins',

  freespins_dtip1: 'Make the deposit & Get',
  freespins_dtip2: 'free spins',
  nofreespins_tip: 'You have no free spins',

  safaritips: 'Use safari to visit this Link (paste in the URl bar) and follow the tips below to install the APP for a better experience',
}