<template>
  <div>
    <v-card-text class="text-center py-15">
      <v-icon
        size="56"
        class="opacity-1"
      >
        mdi-robot-outline
      </v-icon>
      <div class="mt-3">
        Please contact your superior agent to make a deposit!
      </div>
    </v-card-text>

    <v-dialog
      v-model="collection"
      max-width="400"
      scrollable
    >
      <v-card class="box_bg">
        <v-card-title class="text-subtitle-1">
          <v-spacer />
          {{ $t('qdyezh') }}?
          <v-spacer />
        </v-card-title>
        <v-divider class="opacity-3" />
        <v-card-actions class="pa-3">
          <v-spacer />
          <v-btn
            outlined
            color="green darken-1"
            class="px-10"
            @click="collection = false"
          >
            {{ $t('quxiao') }}
          </v-btn>
          <v-spacer />
          <v-btn
            depressed
            dark
            color="green darken-1"
            class="px-10"
            :disabled="collectdisabled"
            @click="usercollect"
          >
            {{ $t('queren') }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="showprogress">
      <v-progress-circular
        indeterminate
      />
    </v-overlay>
  </div>
</template>
<script>
export default {
  name: 'Deposit',
  components:{

  },
  props: {

  },
  data: () => ({
    meminfo:{
      assets: {
        available:0,
      },
      game_balance:0,
      game_platform:'',
      game_platform_id:0
    },
    payments_arr: [

    ],
    collection: false,
    show_payments: true,
    payment_selected: {},
    amounts: [30,50,100,200,300,500,1000,2000,5000],
    select_amount: 50,
    select_amount_error: '',
    allow_first: true,
    first_selected: false,
    bonus_max: 0,
    bonus_ratio: 0,
    balance: 0,
    show: false,
    collectdisabled: false,
    depositdisabled: false,
    showprogress: false,
    hasfree: false,
    freetimes: {},
    freespintimes: 0,
  }),
  computed: {
    gift_amount: function() {
      return Math.min(this.select_amount*this.bonus_ratio,this.bonus_max)
    },
  },
  watch:{
    select_amount: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal)
        this.getfreetimes()
      }
    },
    '$store.state.balance': function (newVal) {
      this.balance = newVal
    },
    showprogress (val) {
      val && setTimeout(() => {
        this.showprogress = false
      }, 5000)
    },
  },
  created() {
    
  },
  mounted() {
    this.getmeminfo()
    this.payments()
    this.showfirst()
    this.promoinfo()
  },
  methods: {
    getmeminfo() {
      let paramObj = {
        member_id: this.getStorage('member_id'),
      }
      this.$server.getmeminfo(paramObj).then((response) => {
        if(response.code==200){
          this.meminfo = response.data
          this.balance = response.data.total_balance
          this.game_balance = response.data.game_balance
          this.game_platform = response.data.game_platform
          this.game_platform_id = response.data.game_platform_id
        }
      })
    },
    payments() {
      let paramObj = {
        member_id: this.getStorage('member_id'),
      }
      this.$server.payments(paramObj).then((response) => {
        if(response.code==200){
          this.payments_arr = response.data
        }
      })
    },
    showfirst() {
      let paramObj = {
        member_id: this.getStorage('member_id'),
      }
      this.$server.showfirst(paramObj).then((response) => {
        if(response.code==200){
          this.allow_first = response.data.allow_first
          this.hasfree = response.data.hasfree
          this.freetimes = response.data.freetimes
          this.getfreetimes()
        }
      })
    },
    promoinfo() {
      let paramObj = {
        promo_id:1
      }
      this.$server.promoinfo(paramObj).then((response) => {
        if(response.code==200){
          this.bonus_max = response.data.conf.bonus_max
          this.bonus_ratio = response.data.conf.bonus_ratio/100
        }
      })
    },
    usercollect() {
      this.collectdisabled = true
      this.showprogress = true
      let paramObj = {
        member_id: this.getStorage('member_id'),
        platform_id: this.meminfo.game_platform_id
      }
      this.$server.usercollect(paramObj).then((response) => {
        if(response.code==200){
          this.getmeminfo()
          this.collectdisabled = false
          this.showprogress = false
          this.collection = false
        }
      })
    },
    deposit() {
      if(this.select_amount<this.payment_selected.min_amount || this.select_amount>this.payment_selected.max_amount){
        //this.$snackbar.warning(this.$t('ckcgxe'))
        this.select_amount_error = this.$t('ckcgxe')
        return false;
      }
      this.showprogress = true
      this.depositdisabled = true
      let paramObj = {
        member_id: this.getStorage('member_id'),
        payment_platform_id:this.payment_selected.id,
        money:this.select_amount,
        first_selected: this.first_selected
      }
      this.$server.deposit(paramObj).then((response) => {
        this.showprogress = false
        this.depositdisabled = true
        if(response.code==200){
          this.$emit("qrcodeshow", this.select_amount, response.data.qr_code, response.data.qr_code_omit, response.data.erweima)
        }
      })
    },
    selectamount(amount) {
      this.select_amount = amount
    },
    select_payment(item) {
      this.payment_selected = item
      this.show_payments = false
    },
    getfreetimes()
    {
      this.freespintimes = 0
      for (const fkey in this.freetimes) {
        if(this.select_amount>=parseInt(fkey)){
          this.freespintimes = this.freetimes[fkey]
        }
      }
    },
    gotofreespin() {
      this.$router.push({
        name: 'FreeSpins',
      })
      this.$emit('fanhui')
    }
  },
};
</script>
<style scoped>
  .CustomRadios .v-radio {
    padding: 8px;
    margin: 5px ;
    min-width: 90%;
    color: var(--v-primary-base);
    border: 1px dashed var(--v-secondary-lighten5);
  }
  .CustomRadios .v-radio.v-item--active{
    background-color: rgba(38, 163, 47, .1);
    border: 1px solid var(--v-primary-base);
  }
  .CustomRadios .v-item--active strong{
    color: var(--v-primary-base);
  }
  .v-application .sum-box{
    position: relative;
    border: 2px solid var(--v-nav_bg-base)!important;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  .sum-box:hover{
    border-color: var(--v-primary-base)!important;
  }
  
  .sum-box.active{
    border-color: var(--v-primary-base)!important;
  }
  .sum-chip{
    position: absolute;
    right: -2px;
    top: -8px;
  }
  .sum-box.active .sum-chip{
    background-color: #FFC107 !important;
    border-color: var(--v-nav_bg-base) !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .sum-check{
    position: absolute;
    bottom: -5px;
    right: -5px;
  }
</style>