import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'
import 'typeface-roboto/index.css'    // 引入本地的Roboto字体资源
import '@mdi/font/css/materialdesignicons.css'  // 引入本地的Material Design Icons资源

Vue.use(Vuetify);

export default new Vuetify({
    icons:{
    iconfont: 'mdi',	// 设置使用本地的icon资源
    },
    theme: { 
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#F0B90B',
                app_bg: '#F6F7FA',
                nav_bg: '#eee',
                btn_bg: '#eaecf3',
                box_bg: '#fff',
                active_bg: '#ccffaa',
                background: '#F6F7FA',
            },
            dark: {
                primary: '#FCD535',
                app_bg: '#24262B',
                nav_bg: '#383b44',
                btn_bg: '#32353b',
                box_bg: '#30323a',
                active_bg: '#85ca00',
                background: '#24262B',
            },

            red: {
                primary: '#f9c735',
                app_bg: '#892322',
                nav_bg: '#651919',
                btn_bg: '#681111',
                box_bg: '#992c29',
                active_bg: '#D04040',
                background: '#401010',
            },
            purple: {
                primary: '#8BC34A',
                app_bg: '#4527A0',
                nav_bg: '#5E35B1',
                btn_bg: '#5E35B1',
                box_bg: '#512DA8',
                active_bg: '#85ca00',
                background: '#4527A0',
            },
            blue: {
                primary: '#2196F3',
                app_bg: '#1A237E',
                nav_bg: '#303F9F',
                btn_bg: '#3949AB',
                box_bg: '#283593',
                active_bg: '#85ca00',
                background: '#181e67',
            },
        },
    },
});
