import axios from 'axios'
import router from '../router/'
import { Encrypt, Decrypt } from '@/utils/aes'
import {getStorage, removeStorage, getDeviceId} from "../module/storage"

axios.defaults.timeout = 20000;
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
//axios.defaults.withCredentials = true; //让ajax携带cookie，但是不能设置 * 跨域，必须是具体域名
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
//http request 拦截器
axios.interceptors.request.use(
  config => {
    //config.data = Encrypt(config.data)
    return config;
  },
  error => {
    return Promise.reject(err);
  }
);

//响应拦截器即异常处理
axios.interceptors.response.use(response => {
    //response.data.data = JSON.parse(Decrypt(response.data.data))
    //console.log(response.data);
    //console.log(response.status);
    //console.log(response.statusText);
    //console.log(response.headers);
    //console.log(response.config);
    return response
}, error => {
    console.log(`${error.response}`)
    if (error.response) {
        /*
        switch (error.response.status) {
            case 401:
                removeStorage("token")
                router.push('/auth/wait')
                break;
            case 403:
                removeStorage("token")
                router.push('/home')
                router.go(0)
                break;
            default:
                break;
        }
        */
    }
    return Promise.reject(error)
})

export function post(url,data = {}){
    if(data.hasOwnProperty('player_id') && data.player_id>0){
        data.k = Encrypt(parseInt(Date.now()/1000).toString()+'_'+data.player_id)
    }else{
        data.k = Encrypt(parseInt(Date.now()/1000).toString()+'_0')
    }
    data.lang = getStorage('Lang')
    return new Promise((resolve,reject) => {
        axios.post(url,JSON.stringify(data),{
            headers:{
                'Content-Type': 'application/json;charset=UTF-8',
                'XX-Token': getStorage("token"),
                'XX-Device-Id': getDeviceId()
            }
        })
        .then(response => {
            resolve(response.data);
        },err => {
            reject(err)
        })
   })
}

export function upload(url,data = {}){
    if(data.hasOwnProperty('player_id') && data.player_id>0){
        data.k = Encrypt(parseInt(Date.now()/1000).toString()+'_'+data.player_id)
    }else{
        data.k = Encrypt(parseInt(Date.now()/1000).toString()+'_0')
    }
    data.lang = getStorage('Lang')
    return new Promise((resolve,reject) => {
        axios.post(url,data,{
            headers:{
                'Content-Type': 'multipart/form-data',
                'XX-Token': getStorage("token"),
                'XX-Device-Id': getDeviceId()
            }
        })
        .then(response => {
            resolve(response.data);
        },err => {
            reject(err)
        })
   })
}

export const server = {
    upload: function(paramObj){
        return upload('/index/upload',paramObj);
    },
    uploadscreenshot: function(paramObj){
        return upload('/index/uploadscreenshot',paramObj);
    },
    uploadtgshare: function(paramObj){
        return upload('/index/uploadtgshare',paramObj);
    },
    uploadfblottery: function(paramObj){
        return upload('/index/uploadfblottery',paramObj);
    },
    uploadvideopubs: function(paramObj){
        return upload('/index/uploadvideopubs',paramObj);
    },
    uploadcomment: function(paramObj){
        return upload('/index/uploadcomment',paramObj);
    },
    conf: function(paramObj){
        return post('/index/conf',paramObj);
    },
    reg: function(paramObj){
        return post('/index/reg',paramObj);
    },
    login: function(paramObj){
        return post('/index/login',paramObj);
    },
    loginbys: function(paramObj){
        return post('/index/loginbys',paramObj);
    },
    sendsms: function(paramObj){
        return post('/index/sendsms',paramObj);
    },
    verifycode: function(paramObj){
        return post('/index/verifycode',paramObj);
    },
    resetpwd: function(paramObj){
        return post('/index/resetpwd',paramObj);
    },
    home_slide: function(paramObj){
        return post('/index/home_slide',paramObj);
    },
    home_lobby: function(paramObj){
        return post('/index/home_lobby',paramObj);
    },
    home_hot: function(paramObj){
        return post('/index/home_hot',paramObj);
    },
    home_p: function(paramObj){
        return post('/index/home_p',paramObj);
    },
    home_slots: function(paramObj){
        return post('/index/home_slots',paramObj);
    },
    home_casino: function(paramObj){
        return post('/index/home_casino',paramObj);
    },
    promolist: function(paramObj){
        return post('/index/promolist',paramObj);
    },
    promoinfo: function(paramObj){
        return post('/index/promoinfo',paramObj);
    },
    viplevels: function(paramObj){
        return post('/index/viplevels',paramObj);
    },
    helps: function(paramObj){
        return post('/index/helps',paramObj);
    },
    helpuseful: function(paramObj){
        return post('/index/helpuseful',paramObj);
    },
    articles: function(paramObj){
        return post('/index/articles',paramObj);
    },
    games_platform: function(paramObj){
        return post('/index/games_platform',paramObj);
    },
    myvip: function(paramObj){
        return post('/index/myvip',paramObj);
    },
    getmeminfo: function(paramObj){
        return post('/index/getmeminfo',paramObj);
    },
    copywriting: function(paramObj){
        return post('/index/copywriting',paramObj);
    },
    bonus777: function(paramObj){
        return post('/index/bonus777',paramObj);
    },
    cpfbonus: function(paramObj){
        return post('/index/cpfbonus',paramObj);
    },
    notify: function(paramObj){
        return post('/index/notify',paramObj);
    },
    ticketurl: function(paramObj){
        return post('/index/ticketurl',paramObj);
    },
    sendsms_change: function(paramObj){
        return post('/index/sendsms_change',paramObj);
    },
    sendemail_change: function(paramObj){
        return post('/index/sendemail_change',paramObj);
    },
    modifymeminfo: function(paramObj){
        return post('/index/modifymeminfo',paramObj);
    },
    changepwd: function(paramObj){
        return post('/index/changepwd',paramObj);
    },
    favlist: function(paramObj){
        return post('/index/favlist',paramObj);
    },
    favadd: function(paramObj){
        return post('/index/favadd',paramObj);
    },
    favcancel: function(paramObj){
        return post('/index/favcancel',paramObj);
    },
    hour3award: function(paramObj){
        return post('/index/hour3award',paramObj);
    },
    pigaward: function(paramObj){
        return post('/index/pigaward',paramObj);
    },
    receive_rebate: function(paramObj){
        return post('/index/receive_rebate',paramObj);
    },
    weeklyinfo: function(paramObj){
        return post('/index/weeklyinfo',paramObj);
    },
    weeklyreceive: function(paramObj){
        return post('/index/weeklyreceive',paramObj);
    },
    invite_home: function(paramObj){
        return post('/index/invite_home',paramObj);
    },
    invite_dashboard: function(paramObj){
        return post('/index/invite_dashboard',paramObj);
    },
    withdraw_commission: function(paramObj){
        return post('/index/withdraw_commission',paramObj);
    },
    invite_dashboard_byday: function(paramObj){
        return post('/index/invite_dashboard_byday',paramObj);
    },
    invite_team: function(paramObj){
        return post('/index/invite_team',paramObj);
    },
    invite_team_list: function(paramObj){
        return post('/index/invite_team_list',paramObj);
    },
    invite_bet: function(paramObj){
        return post('/index/invite_bet',paramObj);
    },
    invite_bet_list: function(paramObj){
        return post('/index/invite_bet_list',paramObj);
    },
    invite_deposit: function(paramObj){
        return post('/index/invite_deposit',paramObj);
    },
    invite_deposit_list: function(paramObj){
        return post('/index/invite_deposit_list',paramObj);
    },
    invite_award: function(paramObj){
        return post('/index/invite_award',paramObj);
    },
    invite_award_list: function(paramObj){
        return post('/index/invite_award_list',paramObj);
    },
    myaward: function(paramObj){
        return post('/index/myaward',paramObj);
    },
    promos: function(paramObj){
        return post('/index/promos',paramObj);
    },
    vippromoinfo: function(paramObj){
        return post('/index/vippromoinfo',paramObj);
    },
    participate: function(paramObj){
        return post('/index/participate',paramObj);
    },
    abandon: function(paramObj){
        return post('/index/abandon',paramObj);
    },
    receive: function(paramObj){
        return post('/index/receive',paramObj);
    },
    depositafter: function(paramObj){
        return post('/index/depositafter',paramObj);
    },
    entergame: function(paramObj){
        return post('/index/entergame',paramObj);
    },
    collect: function(paramObj){
        return post('/index/collect',paramObj);
    },
    usercollect: function(paramObj){
        return post('/index/usercollect',paramObj);
    },
    payments: function(paramObj){
        return post('/index/payments',paramObj);
    },
    proxypay: function(paramObj){
        return post('/index/proxypay',paramObj);
    },
    showfirst: function(paramObj){
        return post('/index/showfirst',paramObj);
    },
    deposit: function(paramObj){
        return post('/index/deposit',paramObj);
    },
    withdraw: function(paramObj){
        return post('/index/withdraw',paramObj);
    },
    record_deposit: function(paramObj){
        return post('/index/record_deposit',paramObj);
    },
    record_withdraw: function(paramObj){
        return post('/index/record_withdraw',paramObj);
    },
    record_award: function(paramObj){
        return post('/index/record_award',paramObj);
    },
    withdraw_cancel: function(paramObj){
        return post('/index/withdraw_cancel',paramObj);
    },
    promorecords: function(paramObj){
        return post('/index/promorecords',paramObj);
    },
    submitrecords: function(paramObj){
        return post('/index/submitrecords',paramObj);
    },
    savescreenshot: function(paramObj){
        return post('/index/savescreenshot',paramObj);
    },
    receivescreenshotaward: function(paramObj){
        return post('/index/receivescreenshotaward',paramObj);
    },
    savetgshare: function(paramObj){
        return post('/index/savetgshare',paramObj);
    },
    tgsharerecords: function(paramObj){
        return post('/index/tgsharerecords',paramObj);
    },
    savefblottery: function(paramObj){
        return post('/index/savefblottery',paramObj);
    },
    fblotteryrecords: function(paramObj){
        return post('/index/fblotteryrecords',paramObj);
    },
    fblotteryresults: function(paramObj){
        return post('/index/fblotteryresults',paramObj);
    },
    whatsapptask: function(paramObj){
        return post('/index/whatsapptask',paramObj);
    },
    whatsappsended: function(paramObj){
        return post('/index/whatsappsended',paramObj);
    },
    whatsappaward: function(paramObj){
        return post('/index/whatsappaward',paramObj);
    },
    eggs: function(paramObj){
        return post('/index/eggs',paramObj);
    },
    smash: function(paramObj){
        return post('/index/smash',paramObj);
    },
    getavideo: function(paramObj){
        return post('/index/getavideo',paramObj);
    },
    videopubs: function(paramObj){
        return post('/index/videopubs',paramObj);
    },
    submitvideopubs: function(paramObj){
        return post('/index/submitvideopubs',paramObj);
    },
    give_away_informed: function(paramObj){
        return post('/index/give_away_informed',paramObj);
    },
    verify: function(paramObj){
        return post('/index/verify',paramObj);
    },
    commentlink: function(paramObj){
        return post('/index/commentlink',paramObj);
    },
    commentrecords: function(paramObj){
        return post('/index/commentrecords',paramObj);
    },
    submitcommentrecords: function(paramObj){
        return post('/index/submitcommentrecords',paramObj);
    },
    freespin: function(paramObj){
        return post('/index/freespin',paramObj);
    },
    findlp: function(paramObj){
        return post('/index/findlp',paramObj);
    },
    cashout_apply: function(paramObj){
        return post('/index/cashout_apply',paramObj);
    },
}