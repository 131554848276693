export default {

  ckcz: '存款充值',
  sxsjj: '3小时奖金',
  tgzq: '推广赚钱',

  dating: '大厅',
  rmyx: '热门游戏',
  pgyx: 'PG游戏',
  slots: 'Slots',
  scj: '收藏夹',
  yhhd: '优惠活动',
  vipgb: 'VIP贵宾',
  help: '帮助中心',
  xitong: '系统',
  qianse: '浅色',
  shense: '深色',

  j30tck: '近30天存款',
  jjzx: '奖金中心',
  grsz: '个人设置',
  tuichu: '退出',

  shouye: '首页',
  youhui: '优惠',
  tuiguang: '推广',
  kefu: '客服',

  denglu: '登录',
  zhuce: '注册',
  yqm: '邀请码',
  sryqm: '输入邀请码',
  rgmyzwxtx: '如果没有则无需填写',
  shoujihao: '手机号',
  qsrsjh: '请输入手机号',
  qsryhm: '请输入用户名',
  mima: '密码',
  qsrmm: '请输入密码',
  wjmm: '忘记密码',
  myzh: '没有账号？',
  yyzh: '已有帐号？',
  czmm: '重置密码',
  yzsjh: '验证手机号',
  qsryzm: '请输入验证码',
  hqyzm: '获取验证码',
  next: '下一步',
  szxmm: '设置新密码',
  szmm: '设置密码',
  qrmm: '确认密码',
  czcg: '重置成功',
  mmszcg: '密码设置成功',
  sjhmgscw: '手机号码格式错误',
  yxgscw: '邮箱格式错误',
  zcsb: '注册失败',
  hmycz: '手机号码已存在',
  dlsb: '登录失败',
  dlsbmmcw: '登陆失败，请检查手机号码或密码',
  dlsbbcz: '手机号码不存在',
  fssb: '发送失败',
  yzsb: '验证失败',
  czmmsb: '重置密码失败',
  qsrxmm: '请输入新密码',
  qqrxmm: '请确认新密码',
  mmbyz: '密码不一致',
  ktjebz: '可提金额不足',
  ckcgxe: '存款超过限额',
  tkcgxe: '提款超过限额',

  jcxx: '基础信息',
  touxiang: '头像',
  yhm: '用户名',
  sjh: '手机号',
  wsz: '未设置',
  sfxx: '身份信息',
  
  zsxm: '真实姓名',
  xing: '姓',
  ming: '名',
  shengri: '生日',
  anquan: '安全',
  xgmm: '修改密码',
  xztx: '选择头像',
  quxiao: '取消',
  qrtj: '确认提交',
  szyhm: '设置用户名',
  yzyx: '验证邮箱',
  qsryx: '请输入邮箱',
  szzsxm: '设置真实姓名',
  szxing: '设置姓',
  szming: '设置名',
  qsrzsxm: '请输入真实姓名',
  qsrxing: '请输入姓',
  qsrming: '请输入名',
  szsr: '设置生日',
  szcpf: '设置CPF',
  qsrcpf: '请输入CPF',
  qsrjmm: '请输入旧密码',
  lcmmbyz: '两次密码不一致',
  xgcg: '修改成功',

  qianbao: '钱包',
  jiangjin: '奖金',
  jilu: '记录',
  cunkuan: '存款',
  tikuan: '提款',
  myrhjl: '没有任何记录',
  tian: '天',
  jinri: '今日',
  sxrq: '筛选日期',
  queding: '确定',
  daizhifu: '待支付',
  wancheng: '完成',
  shibai: '失败',
  qxtk: '取消提款',
  yqbjzwb: '已全部加载完毕',
  jzgd: '加载更多',
  daitikuan: '待提款',
  jlxq: '记录详情',
  jine: '金额',
  shijian: '时间',
  zhuangtai: '状态',
  dingdan: '订单',
  beizhu: '备注',
  leixing: '类型',
  quanding: '确定',

  type_bonus: '存送活动',
  type_hour3award: '3小时奖金',
  type_golden_pig: '储蓄罐',
  type_weekly_deposit_bonus: '周存款奖金',
  type_daily_rebate: '每日返水',
  type_invitation_bonus: '邀请奖金',
  type_bet_commission: '投注佣金',
  type_deposit_commission: '存款佣金',
  scjjmrcs: '首存奖金&每日存送',

  wdjj: '我的奖金',
  lsjl: '历史记录',
  ljlq: '累计领取',
  daijiesuo: '待解锁',
  rwjd: '任务进度',
  jxz: '进行中',
  fangqi: '放弃',
  lqjj: '领取奖金',
  hdlb: '活动列表',
  gzsm: '规则说明',
  lsyq: '流水要求',
  canjia: '参加',
  yswwcdjjrw: '有尚未完成的奖金任务',
  tszncjyg: '同时只能参加一个奖金任务，当一个任务完成或放弃后才可以参加新的奖金任务',
  zdl: '知道了',
  qrfqgjjrw: '确认放弃该奖金任务',
  fqhgjjrwsx: '放弃后该奖金任务将失效',
  queren: '确认',
  gz1: '同时只能参加一个活动',
  gz2: '当上一个活动完成或放弃后才可以参加新的活动',
  gz3: '参加活动需满足对应的存款条件和投注流水要求',
  gz4: '获得的奖金可直接提款或投注',
  cjjjhd: '参加奖金活动',
  hdmc: '活动名称',
  kyye: '可用余额',
  qbye: '钱包余额',
  cyje: '参与金额',
  qsrje: '请输入金额',
  jjbl: '奖金比例',
  jjje: '奖金金额',
  wctzedhkhdjj: '完成投注额度后可获得奖金',
  cglq: '成功领取',
  guanbi: '关闭',
  kyyebz: '可用余额不足',
  qbyebz: '钱包余额不足',
  cjjezsw: '参加金额最少为',
  qdyezh: '确认将游戏余额转回钱包',
  yjgj: '一键归集',

  zffs: '支付方式',
  ckje: '存款金额',
  zengsong: '赠送',
  cjsczs100: '参加首存赠送100%存款奖金',
  tkfs: '提款方式',
  zjlx: '证件类型',
  zjid: 'CPF',
  pixzhlx: 'PIX账号类型',
  zhanghao: 'PIX账号',
  ktje: '可提金额',
  mctkxe: '每次提款限额',
  tksqytj: '提款申请已提交',
  fanhui: '返回',
  jjsm: '奖金说明',
  jjsmnr: 'xxxxxxxxxxxxx',
  zmfk: '怎么付款',
  zmfk2: '粘贴PIX code 到你的钱包app，并确认付款',
  fzpixcode: '复制PIX CODE',
  fzcg: '复制成功',
  ckmx: '存款明细',
  qz10fznfk: '请在10分钟内付款',
  smewm: '扫描二维码',
  yxyezdgj: '游戏余额正在自动归集到钱包',
  tjcg: '提交成功',
  wyzfcg: '我已支付成功',

  tjbzq: '推荐并赚钱',
  yqpybhd: '邀请朋友并获得',
  yibiaopan: '仪表盘',
  tdgl: '团队管理',
  tzyj: '投注佣金',
  ckyj: '存款佣金',
  tjjj: '推荐奖金',
  wdljyj: '我的累计佣金',
  yqjj: '邀请奖金',
  yqpyzq: '邀请朋友&赚钱',
  yqlj: '邀请链接',
  wdyjbl: '我的佣金比例',
  wddj: '我的等级',
  yongjin: '佣金',
  zkqb: '展开全部',
  zhedie: '折叠',
  sjtj: '升级条件',
  yxyq: '有效邀请',
  ljyj: '累计佣金',
  yqjl: '邀请奖励',
  zcbljckdd: '注册并累计存款达到',
  tgndyqlj: '通过您的邀请链接注册加入的每一个玩家您都会收到邀请奖金，邀请的有效人数越多您获得的奖金越多',
  yaoqing: '邀请',
  ygyxyh: '1个有效用户',
  zuigao: '最高',
  yonghu: '用户',
  chaoguo: '超过',
  touzhu: '投注',
  yhckcg: '用户存款超过',
  yhtzcg: '用户投注超过',
  mryqrs: '每日邀请人数',
  khdjjmr: '可获得奖金/每人',
  tghyjb: '推广会员级别',
  ljyjjkl: '累积佣金仅考虑投注佣金和存款佣金',
  dengji: '等级',
  yjbl: '佣金比例',
  guanxi: '关系',
  yqjjpm: '邀请奖金排名',
  yjjjpm: '佣金奖金排名',
  ktyj: '可提佣金',
  lingqu: '领取',
  ytyj: '已提佣金',
  zyqs: '总邀请数',
  ren: '人',
  yjrs: '1级人数',
  ejrs: '2级人数',
  sjrs: '3级人数',
  quanbu: '全部',
  zuori: '昨日',
  benzhou: '本周',
  benyue: '本月',
  shangyue: '上月',
  zyqjj: '总邀请奖金',
  ztzyj: '总投注佣金',
  zckyj: '总存款佣金',
  ztjrs: '总推荐人数',
  yjyj: '1级佣金',
  ejyj: '2级佣金',
  sjyj: '3级佣金',
  qbdj: '全部等级',
  zcrq: '注册日期',
  sousuo: '搜索',
  zcke: '总存款额',
  ztze: '总投注额',
  yjrq: '佣金日期',
  tzrs: '投注人数',
  lqsj: '领取时间',
  ckrs: '存款人数',
  yxyqjj: '有效邀请奖金',
  jjrq: '奖金日期',
  zcyhs: '注册用户数',

  qbvipdj: '全部VIP等级',
  dqdj: '当前等级',
  j30tljck: '近30天累计存款',
  rgysjd: '如果要升级到',
  nxyzck: '您需要再存款',
  daojishi: '倒计时',
  cxg: '储蓄罐',
  zckjj: '周存款奖金',
  zckjj_sub: '周存款奖金sub',
  rfsjj: '日返水奖金',
  lhjfs: '老虎机返水',
  zrfs: '真人返水',
  yidacheng: '已达成',
  dengjiyaoqiu: '等级要求',
  sstckxdd: '30天存款需达到',
  hxyzck: '还需要再存款',
  quanyi: '权益',
  laohuji: '老虎机',
  zhenren: '真人',

  hdsj: '活动时间',
  paixu: '排序',
  wdgd: '我的工单',
  tjxgd: '提交新工单',
  cjwt: '常见问题',
  gengduo: '更多',
  youbz: '有帮助',
  meiyong: '没用',
  sfzxkf: '是否咨询客服',

  qbtz: '全部投注',
  dayingjia: '大赢家',
  gbdj: '高倍大奖',
  youxi: '游戏',
  wanjia: '玩家',
  beishu: '倍数',
  shuying: '输赢',

  suoding: '锁定',
  wxts: '温馨提示',
  ddcnlq: 'VIP等级达到2级才能领取',
  mzznlqyc: '每周只能领取一次',

  cjwd: '常见问答',
  cpfgeshi: 'CPF必须为11位数字',
  kyyetip: '存款金额需完成一倍流水',
  mfzs: '免费赠送',
  mfzs2: '完成CPF设置即可获得R$ 7.77 免费彩金!',
  cpftip1: '填写CPF可获得R$7.77免费彩金!',
  cpftip2: '填写CPF可获得R$7.77免费彩金!',

  riqi: '日期',
  ewjl: '额外奖励',
  zhouyi: '周一',
  zhouer: '周二',
  zhousan: '周三',
  zhousi: '周四',
  zhouwu: '周五',
  zhouliu: '周六',
  zhouri: '周日',
  yilingqu: '已领取',
  weilingqu: '未领取',
  zckjj1: '每周累计存款超过R$1000BRL可领取下周存款奖金。',
  zckjj2: '鼓励金从周一到周日每天可领取一次。',
  zckjj3: '如当天奖金未领取将会失效。',
  zckjj4: '每周四的奖金会自动加倍。',
  zckjj5: '每周领取奖金超过5天，周日可额外赠送R$18BRL奖金。',
  szlj: '您上周累计存款',
  weiman: '未满',
  yiman: '已满',

  ywccpfsz: '已完成CPF设置',
  xiazai: '下载',
  xiazai_tip_text: '更好的游戏体验',
  srwz: '输入我们网址领取!',
  qzxfsr: '请在下方输入',
  websiteerror: '网址输入错误',
  xcnr: '在 Facebook 和公共群组上发布网页截图',

  newtip_biaoti: '温馨提示',
  newtip_neirong: '为了更好的游戏体验，请下载YeloBet.com App',

  reg_freebonus: '新用户注册赠送 R$ 12,77 免费奖金!',

  azdsjzm: '安装到手机桌面',
  azdsjzmnr1: '点击Safari浏览器中',
  azdsjzmnr2: '这个图标，将网站图标添加到手机桌面，方便以后访问。',
  rhcz: '如何操作',
  wyaz: '我已安装',

  wckts: '免费彩金最高可提R$ 30，其它金额将自动扣除',
  wckts2: '将网页截图发布到 Facebook 和公共群组，您的提款将更快得到处理！',

  scjtbt: '在 Facebook 公共群组中发布您的邀请信息',
  scjt: '上传截图',
  lq_x: '领取',
  x_jj: '奖金',
  jlck: '举例参考',
  daishenhe: '待审核',
  yifafang: '已发放',
  weitongguo: '未通过',
  tiezilianjie: '帖子链接',
  tijiao: '提交',
  upimg_a01: '请使用您的推荐链接，您需要写一篇推广文章。',
  upimg_a02: '(例如：介绍新玩家注册奖金 R$ 7.77)',
  upimg_a03: '发布到任何 Facebook 公共群组。',
  guize: '规则',
  upimg_b01: '您上传的截图需要审核。',
  upimg_b02: '审核将在 1 小时内完成。',
  upimg_b03: '审核通过后，我们将向您的账户发放 R$ 6 奖励。',
  upimg_b04: '请确保每次上传的 帖子 都是今天的 帖子',
  zs1zt: '请至少上传一张图片',
  tgljbnwk: '帖子链接不能为空',
  tgljycz: '帖子链接已存在',
  xctjsj: '请在 1 小时后再在 facebook 上发布并上传新的截图',
  xctjsj2: '请在 1 小时后再在 whatsapp 上发布并上传新的截图',
  ytzntjyc: '1天只能提交1次',
  ytzntj2c: '1天只能提交2次',
  ytzntj2c2: '1天只能提交2次',
  yjfz: '一键复制',
  ljgscw: '请输入正确帖子链接',
  xzwfsy: '现在无法使用，请在 09:00 至 24:00 之间发布和上传',

  post_ad1: '发布到 Facebook 群组',
  shezhi: '设置',
  shangchuan: '上传',
  wcCPFsz: '完成 CPF 设置',
  upappimg: '上传手机APP截图',
  shsb_cxsc: '审核失败，请重新上传.',
  upappimg_rule1: '下载安装YeloBet.com官方APP',  
  upappimg_rule2: '安装完成后截图手机桌面',  
  upappimg_rule3: '截图中能看到YeloBet.com APP图标即可',

  post_tg: '分享到 Telegram 群组',
  post_tg_img: '上传手机APP截图',
  post_tg_end: '今日分享次数已达上限，请明天继续分享',
  post_tg_rule1: '下载 YeloBet.com 官方 APP',
  post_tg_rule2: '安装完成后截图手机桌面',

  post_fb_tt: '分享到 Facebook 群组',
  post_fb_tt2: '分享帖子，有机会赢取 R$ 2000 大奖',
  huodong: '活动',
  kaijiangjieguo: '开奖结果',
  canyurenshu: '参与人数',
  huodongshijian: '活动时间: 11 月 20 日 - 11 月 25 日',
  post_fb_bonus1: '一等奖 R$ 2000',
  post_fb_bonus2: '二等奖 R$ 500',
  post_fb_bonus3: '三等奖 R$ 1000',
  post_fb_bonus4: '4-10等奖 R$ 100',
  post_fb_bonus5: '11-100等奖 R$ 50',
  post_fb_bonus6: '101-200等奖 R$ 20',
  post_fb_rule1: '点击 👇 分享帖子并回复评论 + @您的朋友',
  post_fb_rule2: '提交您的分享截图',
  post_fb_rule3: '我们将审核您上传的截图。',
  post_fb_rule4: '等待 11 月 25 日公布抽奖名单',
  post_fb_btn: 'Facebook Lucky draw',
  daikaijiang: '待开奖',
  zhongjiang: '中奖',
  weizhongjiang: '未中奖',
  post_ad3: '赢取 Iphone15、三星手机和真钱',

  dlcgts: '您已成功登录。',
  ckts: '存款并享受游戏！',
  sc100jj: '首次存款 100% 奖金',
  ljck: '立即存款',

  mtsdyj: "您可以在明天收到今天的佣金",
  ni: '你',
  yjms: '佣金模式',
  cengji: '层级',

  sendws: '通过发送 WhatsApp 消息赚钱',

  browser_tip1: '方法一',
  browser_tip2: '方法二',
  browser_tip_dianji: '点击',
  browser_tip_xuanze: '选择',
  browser_tip1_1: '打开菜单栏',
  browser_tip1_2: '在浏览器中打开',
  browser_tip2_1: '复制',
  browser_tip2_2: '粘贴到浏览器中打开',

  mrzjdbtn: '免费砸金蛋',
  mrzjdbtn_i: '每日幸运金蛋',
  mrzjd: '每日免费砸金蛋',
  hyjhcs1: '您还有',
  hyjhcs2: '次机会',
  mrzjdgz1: '每日存款超过 R$ 20 BRL，次日可获得1次免费砸金蛋机会。',
  mrzjdgz2: '砸金蛋机会仅限当日有效，过期自动失效。',
  mrzjdgz3: '中奖金额将自动进入您的账户余额。',

  license_nav: '博彩执照',
  license_tt: '库拉索许可证',
  license_subtt: 'BonusPG.com - 责任赌场',
  license_nei: '库拉索岛博彩执照是世界上最受欢迎的电子博彩执照之一。主要的软件供应商都获得了库拉索岛博彩管理委员会的许可。许可证涵盖所有游戏，如在线赌场、老虎机、体育博彩和电子游戏。田径、彩票以及技巧和机会游戏。该许可证颁发机构得到库拉索岛政府的支持，其成立是为了确保所有运营商遵守监管框架和行为准则。以下是平台许可证（BonusPG.com）的说明。请勿盗窃，违者必究。',

  freespins_t1: '今日可用免费旋转次数',
  freespins_t2: '次',
  freespins_t3a: '您一共有',
  freespins_t3b: '次免费旋转机会',
  freespins_t4: '个免费 Spins 现在可用',
  freespins_t5: '剩余的免费 Spins 可在明天使用',
  freespins_tip1: '免费旋转奖金如何使用？',
  freespins_tip2: '老虎机游戏的免费旋转，您可以在不下注任何真钱的情况下享受游戏的结果。 在某些情况下，例如作为完成挑战的奖励，或者作为促销活动的一部分，玩家可以获得免费旋转奖励。',
  freespins_tip3: '*根据您前三笔存款的金额，每天可获得不同数量的免费旋转币。您可以立即使用今天的一半免费旋转币，并将剩下的一半留到明天使用。',
  freespins_tip4: '每次存款 ≥ 10 雷亚尔，获得 1 个免费旋转',
  freespins_tip5: '每次存款 ≥ R$30，获得 5 个免费旋转',
  freespins_tip6: '每次存款 ≥ R$100，获得 15 个免费旋转',
  freespins_tip7: '免费 Spins 可用于指定的符合条件的游戏，每次完成免费 Spins 后，赢取的奖金将转换为赌场即时真钱。',
  freespins_tip8: '现在免费旋转只适用于 PG -FORTUNE TIGER',
  freespins_tip9: '免费旋转游戏的总投注额为 0.4 雷亚尔',
  freespins_btn: '进入免费旋转游戏',
}