import Vue from 'vue'
import VueRouter from 'vue-router'
import { DefaultLayout } from '@/components/layouts'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    redirect: {name:'Home'},
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
      },
      {
        path: '/home/bonus',
        name: 'homebonus',
        component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
      },
      {
        path: '/reg',
        name: 'reg',
        component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
      },
      {
        path: '/promo',
        name: 'promo',
        component: () => import(/* webpackChunkName: "promo" */ '../views/Promo.vue')
      },
      {
        path: '/help',
        name: 'help',
        component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue')
      },
      {
        path: '/vip',
        name: 'vip',
        component: () => import(/* webpackChunkName: "vip" */ '../views/Vip.vue')
      },
      {
        path: '/vip/bonus',
        name: 'vipbonus',
        component: () => import(/* webpackChunkName: "vip" */ '../views/Vip.vue')
      },
      {
        path: '/invite',
        name: 'invite',
        component: () => import(/* webpackChunkName: "invite" */ '../views/Invite.vue')
      },
      {
        path: '/fav',
        name: 'fav',
        component: () => import(/* webpackChunkName: "fav" */ '../views/Fav.vue')
      },
      {
        path: '/slots',
        name: 'slots',
        component: () => import(/* webpackChunkName: "slots" */ '../views/Slots.vue')
      },
      {
        path: '/p/:id',
        name: 'p',
        component: () => import(/* webpackChunkName: "p" */ '../views/P.vue')
      },
      {
        path: '/hot',
        name: 'hot',
        component: () => import(/* webpackChunkName: "hot" */ '../views/Hot.vue')
      },
      {
        path: '/ticket',
        name: 'ticket',
        component: () => import(/* webpackChunkName: "ticket" */ '../views/Ticket.vue')
      },
      {
        path: '/download',
        name: 'download',
        component: () => import(/* webpackChunkName: "download" */ '../views/Download.vue')
      },
      {
        path: '/whatsapp',
        name: 'whatsapp',
        component: () => import(/* webpackChunkName: "whatsapp" */ '../views/Whatsapp.vue')
      },
      {
        path: '/facebook',
        name: 'facebook',
        component: () => import(/* webpackChunkName: "facebook" */ '../views/Facebook.vue')
      },
      {
        path: '/sendmessage',
        name: 'sendmessage',
        component: () => import(/* webpackChunkName: "sendmessage" */ '../views/SendMessage.vue')
      },
      {
        path: '/upvideo',
        name: 'upvideo',
        component: () => import(/* webpackChunkName: "upvideo" */ '../views/UpVideo.vue')
      },
      {
        path: '/posturl',
        name: 'posturl',
        component: () => import(/* webpackChunkName: "posturl" */ '../views/PostURL.vue')
      },
      {
        path: '/license',
        name: 'license',
        component: () => import(/* webpackChunkName: "license" */ '../views/License.vue')
      },
      {
        path: '/freespins',
        name: 'FreeSpins',
        component: () => import(/* webpackChunkName: "freespins" */ '../views/FreeSpins.vue')
      },
    ]
  },
  {
    path: '/i',
    name: 'i',
    component: () => import(/* webpackChunkName: "i" */ '../views/ext/I.vue')
  },
  {
    path: '/game/:id',
    name: 'game_id',
    component: () => import(/* webpackChunkName: "game_id" */ '../views/pages/Game_id.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
